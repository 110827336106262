import React, { useEffect, useState } from "react";
import { debounce } from "lodash";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays, faBackward, faPlay, faForward, faPause, faMinus, faSpinner } from "@fortawesome/free-solid-svg-icons";

import { notification } from "antd";
import { getHistoryDates, getHistoryList } from "../../services/axios";
import { formatDateTime, formatDateTimeSec, formatTimeFromSec, getAngleBetweenPoints, sleep } from "../../utils/globals";
import MapContainer from "../commons/maps/MapContainer";
import "./History.css";
import CustomDatePicker from "../commons/datepicker/CustomDatePicker";

const PlaySpeeds = [1, 5, 10, 20, 60, 600];

const History = () => {

  const getData = useLocation();
  const data = {
    vehicleInfo: getData.state?.vehicleInfo,
  };

  const [directionsService, setDirectionsService] = useState(null);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [isHistoryPlay, setIsHistoryPlay] = useState(false);
  const [historyData, setHistoryData] = useState([]);
  const [highlightDates, setHighlightDates] = useState([]);

  const [isPlaying, setIsPlaying] = useState(false);
  const [sliderMaxValue, setSliderMaxValue] = useState(100);
  const [sliderValue, setSliderValue] = useState(0);
  const [playSpeed, setPlaySpeed] = useState(1);

  const [device, setDevice] = useState(null);

  useEffect(() => {
    if (data.vehicleInfo) {
      loadHistoryDates();
    }
  }, [data?.vehicleInfo?.deviceImei]);

  const loadHistoryDates = async () => {
    const res = await getHistoryDates({
      deviceImei: data.vehicleInfo.deviceImei,
    });
    if (res.status == 200 && res.data.result.length > 0) {
      setHighlightDates(res.data.result.map(item => new Date(item._id)));
    }
  }

  const handleSearch = async () => {
    if (startDate === "" || startDate === null) {
      notification.warning({ description: "Please select the Start Date!" });
      return;
    }
    if (endDate === "" || endDate === null) {
      notification.warning({ description: "Please select the End Date!" });
      return;
    }

    setIsLoading(true);
    const res = await getHistoryList({
      deviceImei: data?.vehicleInfo?.deviceImei,
      startDate: startDate,
      endDate: endDate
    });
    if (res.status == 200 && res.data.result.length > 0) {
      let ret = await getAdjustedData(res.data.result);
      setHistoryData(ret);
      setDevice({
        ...(data.vehicleInfo),
        lat: ret[0].lat,
        lng: ret[0].lng,
        speed: ret[0].speed,
        connected: true,
        idle: false,
        angle: getAngleBetweenPoints(ret[0].lat, ret[0].lng, ret[1]?.lat, ret[1]?.lng)
      });
      // calc slider value
      let diff = new Date(ret[ret.length - 1].transferDate).getTime() - new Date(ret[0].transferDate).getTime();
      setSliderMaxValue(diff / 1000);
      setIsHistoryPlay(true);
    }
    setIsLoading(false);
  };


  const getAdjustedData = async (trackPoints) => {
    if (!directionsService) {
      return trackPoints;
    }

    const getSpeed = (time, data) => {
      for (const item of data) {
        let curtime = new Date(item.transferDate).getTime();
        if (curtime >= time) {
          return item.speed;
        }
      }
      return 0;
    }

    const timeDiff = 3 * 60 * 1000;   // 3 min
    const points = [];
    let tempPoints = [];
    let prevPoint = trackPoints[0];
    let prevTime = new Date(prevPoint.transferDate).getTime();
    for (let i = 0; i < trackPoints.length; i++) {
      let curPoint = trackPoints[i];
      let curTime = new Date(curPoint.transferDate).getTime();
      // if (curPoint.speed == 0) {
      //   points.push(curPoint);
      //   prevPoint = curPoint;
      //   prevTime = new Date(prevPoint.transferDate).getTime();
      //   continue;
      // }
      if (curTime - prevTime > timeDiff) {
        try {
          const res = await directionsService.route({
            origin: prevPoint,
            destination: curPoint,
            travelMode: google.maps.TravelMode.DRIVING,
            provideRouteAlternatives: true
          });
          if (res.routes?.length > 0) {
            let count = res.routes[0].overview_path.length;
            let interval = (curTime - prevTime) / count;
            tempPoints = res.routes[0].overview_path.map((item, index) => ({
              lat: item.lat(), lng: item.lng(),
              speed: getSpeed(new Date(prevTime + index * interval).getTime(), tempPoints), 
              transferDate: new Date(prevTime + index * interval)
            }));
          }
        } catch (err) { }

        points.push(...tempPoints);
        tempPoints = [];
        prevPoint = curPoint;
        prevTime = new Date(prevPoint.transferDate).getTime();
      }
      tempPoints.push(curPoint);
    }
    if (tempPoints.length > 0) {
      // console.log("🚀 ~ getAdjustedData ~ tempPoints:", tempPoints)
      try {
        const res = await directionsService.route({
          origin: tempPoints[0],
          destination: tempPoints[tempPoints.length - 1],
          travelMode: google.maps.TravelMode.DRIVING,
          provideRouteAlternatives: true
        });
        if (res.routes?.length > 0) {
          let count = res.routes[0].overview_path.length;
          let prevTime = new Date(tempPoints[0].transferDate).getTime();
          let curTime = new Date(tempPoints[tempPoints.length - 1].transferDate).getTime();
          let interval = (curTime - prevTime) / count;
          tempPoints = res.routes[0].overview_path.map((item, index) => ({
            lat: item.lat(), lng: item.lng(),
            speed: getSpeed(new Date(prevTime + index * interval).getTime(), tempPoints),
            transferDate: new Date(prevTime + index * interval)
          }));
        }
      } catch (err) { }
      points.push(...tempPoints);
    }
    if (points[0].lat != trackPoints[0].lat || points[0].lng != trackPoints[0].lng) {
      tempPoints = [trackPoints[0]];
      try {
        const res = await directionsService.route({
          origin: trackPoints[0],
          destination: points[0],
          travelMode: google.maps.TravelMode.DRIVING,
          provideRouteAlternatives: true
        });
        if (res.routes?.length > 0) {
          let count = res.routes[0].overview_path.length;
          let prevTime = new Date(trackPoints[0].transferDate).getTime();
          let curTime = new Date(points[0].transferDate).getTime();
          let interval = (curTime - prevTime) / count;
          tempPoints = res.routes[0].overview_path.map((item, index) => ({
            lat: item.lat(), lng: item.lng(),
            speed: trackPoints[0].speed,
            transferDate: new Date(prevTime + index * interval)
          }));
        }
      } catch (err) { }
      points.unshift(...tempPoints);
    }
    // console.log("🚀 ~ getAdjustedData ~ points:", points)

    return points;
  }


  const handleClosePlayback = () => {
    setIsHistoryPlay(false);
    setHistoryData([]);
    setSliderValue(0);
    setDevice(null);
    setIsPlaying(false);
  }

  const handleDecSpeed = () => {
    if (playSpeed > 1) {
      setPlaySpeed(PlaySpeeds[PlaySpeeds.indexOf(playSpeed) - 1]);
    }
  }

  const handleIncSpeed = () => {
    if (playSpeed < 600) {
      setPlaySpeed(PlaySpeeds[PlaySpeeds.indexOf(playSpeed) + 1]);
    }
  }
  const handleStartStop = () => {
    setIsPlaying(!isPlaying);
  }
  const handleSlider = (e) => {
    setSliderValue(Number(e.target.value));
  }

  useEffect(() => {
    const updateVehiclePos = debounce(async () => {
      const len = historyData.length;
      if (len == 0) {
        return;
      }

      const startTime = new Date(historyData[0].transferDate).getTime() / 1000;
      for (let i = 1; i < len; i++) {
        const trackData = historyData[i];

        let nextTime = new Date(trackData.transferDate).getTime() / 1000;
        if (sliderValue <= nextTime - startTime) {
          let prevData = historyData[i - 1];
          let prevLat = prevData.lat;
          let prevLng = prevData.lng;
          let nextLat = trackData.lat;
          let nextLng = trackData.lng;

          let prevTime = new Date(prevData.transferDate).getTime() / 1000;

          let total = nextTime - prevTime;
          let offset = sliderValue + startTime - prevTime;
          let percent = total > 0 ? offset / total : 0;

          let curLat = prevLat + (nextLat - prevLat) * percent;
          let curLng = prevLng + (nextLng - prevLng) * percent;
          let curDateTime = new Date((prevTime + offset) * 1000);
          
          setDevice({
            ...(data.vehicleInfo),
            lat: curLat,
            lng: curLng,
            speed: prevData.speed,
            connected: true,
            idle: false,
            angle: getAngleBetweenPoints(prevLat, prevLng, nextLat, nextLng),
            positionTime: formatDateTimeSec(curDateTime)
          });
          break;
        }
      }

    }, 10);
    updateVehiclePos();

    return () => {
      updateVehiclePos.cancel();
    };

  }, [sliderValue]);

  useEffect(() => {
    let intervalId;
    if (isPlaying) {
      intervalId = setInterval(() => {
        setSliderValue(prevVal => {
          if (prevVal + playSpeed >= sliderMaxValue) {
            setIsPlaying(false);
            return sliderMaxValue;
          } else {
            return prevVal + playSpeed;
          }
        })
      }, 1000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isPlaying, playSpeed]);


  return (
    <div className="map-container position-relative d-flex justify-content-center w-100">
      {isHistoryPlay ?
        <>
          <div className="history-playback-panel">
            <h3>History Playback</h3>
            <div>{data?.vehicleInfo?.vehicleName}</div>
            <div>
              {formatDateTime(startDate)} - {formatDateTime(endDate)}
            </div>
            <img src="/assets/close.svg" alt="none"
              className="position-absolute top-0 end-0 mt-2 me-2 cursor-pointer"
              onClick={() => handleClosePlayback()} />
          </div>

          <div className="player-main">
            <div className="left-player-main">
              <div className="sub1-player-main-div1">
                <div className="subsub1-sub1-player-main-div1" onClick={handleDecSpeed}>
                  <FontAwesomeIcon icon={faBackward} />
                </div>
                <div
                  className="subsub2-sub1-player-main-div1"
                  onClick={() => handleStartStop()}
                >
                  <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />
                </div>
                <div className="subsub3-sub1-player-main-div1" onClick={handleIncSpeed}>
                  <FontAwesomeIcon icon={faForward} />
                </div>
              </div>
              <div className="sub2-player-main-div1">
                <p >{playSpeed + "x"}</p>
              </div>
            </div>

            <div className="right-player-main">
              <div className="sub1-right-player-main">
                <div className="subsub1-sub1-right-player-main">
                  {formatTimeFromSec(sliderValue)} / {formatTimeFromSec(sliderMaxValue)}
                </div>
                <div className="subsub2-sub1-right-player-main">
                  <input type="range" className="range" min={0} max={sliderMaxValue} value={sliderValue}
                    style={{ "--min": 0, "--max": sliderMaxValue, "--value": sliderValue }}
                    onChange={(e) => { handleSlider(e) }} />
                </div>
              </div>
              <div className="sub2-right-player-main">
                <p>{formatDateTime(startDate)}</p>
                <span><FontAwesomeIcon icon={faMinus} /></span>
                <p>{formatDateTime(endDate)}</p>
              </div>
            </div>
          </div>
        </>
        :
        <div className="w-100 history-search-panel-wrapper">
          <div className="d-flex justify-content-between align-items-center position-relative gap-4 history-search-panel">
            {isLoading && (
              <div className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center text-light bg-dark bg-opacity-75 rounded-4 z-3">
                <FontAwesomeIcon icon={faSpinner} spin size="2x" />
              </div>
            )}
            <div className="d-flex align-items-center gap-4 ms-3 mb-0 mb-sm-5">
              <img src="/assets/Searchwithperson.svg" alt="none" className="history-search-icon" />
              <span className="panel-title">Search Vehicle History</span>
            </div>

            <div className="d-flex align-items-center gap-5 mb-0 mb-sm-5">
              <span className="vehicle-name">{data?.vehicleInfo?.vehicleName}</span>
              <img className="history-car-icon" alt="none"
                src={`/assets/${data?.vehicleInfo.vehicleType}.svg`} />
            </div>

            <div className="d-flex flex-column flex-sm-row align-items-center gap-4 gap-sm-5">
              <div>
                <div className="">From</div>
                <div className="field-input position-relative">
                  <CustomDatePicker
                    placeholderText="From"
                    selectedDate={startDate}
                    onChange={(date) => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    maxDate={endDate}
                    highlightDates={highlightDates}
                  />
                  <FontAwesomeIcon
                    className="position-absolute top-0 end-0 mt-2 me-3"
                    icon={faCalendarDays} />
                </div>
              </div>
              <div>
                <div className="">To</div>
                <div className="field-input position-relative">
                  <CustomDatePicker
                    placeholderText="To"
                    selectedDate={endDate}
                    onChange={(date) => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    highlightDates={highlightDates}
                    maxDate={new Date()}
                    maxTime={new Date()}
                  />
                  <FontAwesomeIcon
                    className="position-absolute top-0 end-0 mt-2 me-3"
                    icon={faCalendarDays} />
                </div>
              </div>
            </div>

            <div className="tab-button d-flex justify-content-center align-items-center mx-1" onClick={handleSearch}>
              <img src="/assets/Searchwithpersonwhite.svg" alt="none" />
              <button>Search</button>
            </div>
          </div>
        </div>
      }

      <MapContainer devices={device ? [device] : []} point={historyData?.length > 0 ? historyData[0] : null} trackPoints={historyData} setDirectionsService={setDirectionsService} />
    </div>
  );
};

export default History;
