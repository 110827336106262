import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, Modal, notification } from 'antd';
import { assignCompanyVehicles } from '../../../services/axios';
import { getDevices } from '../../../redux/actions/devices';
import { showConfirmEx } from '../../../utils/globals';
import Search from 'antd/es/input/Search';

const AssignVehicleCompany = ({ isShowModal, onClose, company }) => {

    const dispatch = useDispatch();
    const devices = useSelector((state) => state.devicesList.devices);
    const [devicesData, setDevicesData] = useState(devices);

    const [checkAll, setCheckAll] = useState(false);
    const [checkedList, setCheckedList] = useState([]);
    const [keyword, setKeyword] = useState("");

    useEffect(() => {
        setDevicesData(devices);
    }, [devices]);

    useEffect(() => {
        const checkedDevices = [];
        if (devices) {
            for (const item of devices) {
                if (item.company?._id == company?._id) {
                    checkedDevices.push(item.deviceImei);
                }
            }
        }
        setCheckedList(checkedDevices);
    }, [company]);

    const handleSearch = (e) => {
        setKeyword(e.target.value);
    }

    const handleCheck = (checked, item) => {
        const checkedDevices = [...checkedList];
        const index = checkedDevices.findIndex(d => d == item.deviceImei);
        if (index != -1) {
            checkedDevices.splice(index, 1);
        }
        if (checked) {
            checkedDevices.push(item.deviceImei);
        }
        setCheckedList(checkedDevices);
    }

    const handleAssign = async () => {
        showConfirmEx(`Are You sure to Assign selected vehicles to company ${company?.name}?`)
            .then(async () => {
                const res = await assignCompanyVehicles({
                    deviceImeis: checkedList,
                    companyId: company?._id
                });
                if (res.status == 200) {
                    notification.success({
                        description: "Assigned successfully!",
                    });
                    onClose();
                    dispatch(getDevices(false));
                } else {
                    notification.error({
                        description: "Something went wrong!",
                    });
                }
            })
            .catch(() => {
            });
    }

    return (
        <Modal
            title="Assign Vehicles"
            centered
            open={isShowModal}
            onOk={() => onClose()}
            onCancel={() => onClose()}
            width={800}
            style={{ maxWidth: "100%" }}
            className='assing-vehicle-modal'
            footer={false}
        >
            <div className='pt-4'>
                <div className='mb-3'>
                    <Search
                        size='large'
                        className='w-50'
                        placeholder='Plate No, IMEI No.'
                        onChange={handleSearch}
                    />
                </div>
                <div className="list-header py-3">
                    <p className='text-center'>
                        {/* <Checkbox /> */}
                    </p>
                    <p>Plate No.</p>
                    <p>Device</p>
                    <p>Model</p>
                    <p>IMEI No.</p>
                </div>
                <div className="list-body overflow-auto" style={{ minHeight: '25rem' }}>
                    {devicesData
                        ?.filter(item => item.deviceImei?.toLocaleLowerCase().includes(keyword.toLocaleLowerCase())
                            || item.vehicleName?.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()))
                        ?.map((item, index) => (
                            <div key={index}>
                                <p className='text-center'>
                                    <Checkbox
                                        checked={checkedList.includes(item?.deviceImei)}
                                        onChange={(e) => handleCheck(e.target.checked, item)}
                                    />
                                </p>
                                <p>{item?.vehicleName}</p>
                                <p>{item?.deviceType}</p>
                                <p>{item?.deviceModel}</p>
                                <p>{item?.deviceImei}</p>
                            </div>
                        ))}
                </div>
                <div className='mt-4 d-flex justify-content-end footer'>
                    <button className='d-flex align-items-center' onClick={handleAssign}><img src="/assets/addbtn.svg" alt="none" className='me-3' /> Assign to Company</button>
                </div>
            </div>
        </Modal>
    )
}

export default AssignVehicleCompany;
