import React, { useEffect, useState } from "react";
import { NavLink, Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { useOutsideClick } from "../../../hooks/useOutsideClick.js";
import {
  faChartPie,
  faUser,
  faMicrochip,
  faBell,
  faFileContract,
  faFileCircleCheck,
  faMapLocationDot,
  faBuilding,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Dropdown, notification } from "antd";

import {
  LOG_OUT,
  SET_USER,
  SWITCH_MENU_VISIBLE,
  SWITCH_SIDEBAR_VISIBLE,
  TOGGLE_MAP_MODE,
} from "../../../redux/store/types.js";

import "./Header.css";
import { UserRole } from "../../../common/common.js";
import { getUnreadAlertCount, getUnreadAlertList, setAlertsRead } from "../../../services/axios.js";
import { formatDateTime, formatDateTimeSec } from "../../../utils/globals.js";
import { socket } from "../../../socket.js";

const tabs = [
  {
    to: "/Dashboard",
    icon: faChartPie,
    label: "Dashboard",
    onlyAdmin: false,
  },
  {
    to: "/Users",
    icon: faUser,
    label: "Users",
    onlyAdmin: true,
  },
  {
    to: "/Devices",
    icon: faMicrochip,
    label: "Devices",
    onlyAdmin: false,
  },
  {
    to: "/Alerts",
    icon: faBell,
    label: "Alerts",
    onlyAdmin: false,
  },
  {
    to: "/Reports",
    icon: faFileContract,
    label: "Reports",
    onlyAdmin: false,
  },
  {
    to: "/Rules",
    icon: faFileCircleCheck,
    label: "Rules",
    onlyAdmin: false,
  },
  {
    to: "/Geofence",
    icon: faMapLocationDot,
    label: "Geofence",
    onlyAdmin: false,
  },
  {
    to: "/Company",
    icon: faBuilding,
    label: "Company",
    onlyAdmin: true,
  },
  {
    to: "/Ticket",
    icon: faTriangleExclamation,
    label: "Tickets",
    onlyAdmin: false,
  },
];

notification.config({
  top: 120,
  duration: 3,
});


const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width: 768px)");

  const user = useSelector((state) => state.auth.user);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const showMenu = useSelector((state) => state.global.showMenu);
  const mapMode = useSelector((state) => state.global.mapMode);

  const [alertCount, setAlertCount] = useState(0);
  const [alerts, setAlerts] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorMapSelectEl, setAnchorMapSelectEl] = useState(null);

  const open = Boolean(anchorEl);
  const openMapSelect = Boolean(anchorMapSelectEl);

  const menuRef = useOutsideClick(() => {
    handleMobileSidebarControlClick();
  });

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }

    getAlertStatus();

    function onAlertOccured(data) {
      if (data.notify) {
        notification.warning({
          message: data.device,
          description: data.message,
        });
      }
      getAlertStatus();
    }
    socket.on('alertOccured', onAlertOccured);

    return () => {
      socket.off('alertOccured', onAlertOccured);
    }

  }, [isAuthenticated]);

  const getAlertStatus = async () => {
    const res = await getUnreadAlertCount();
    if (res?.status == 200) {
      setAlertCount(res.data.count);
    }
  }

  const handleLogout = () => {
    dispatch({ type: LOG_OUT });
    localStorage.removeItem("token");
    setAnchorEl(null);
    navigate("/login");
  };

  const isImpersonate = () => {
    return localStorage.getItem("token_m")?.length > 0 && localStorage.getItem("token_m") != localStorage.getItem("token");
  }
  const handleDeimpersonate = () => {
    dispatch({
      type: SET_USER,
      deimpersonate: true
    });
    // localStorage.setItem("reload", "1");
    // navigate("/Dashboard");
    setTimeout(() => navigate(0), 500);
  };

  const toggleMapMode = (mode) => {
    dispatch({ type: TOGGLE_MAP_MODE, payload: mode });
    setAnchorMapSelectEl(null);
  };

  const handleMobileSidebarControlClick = () => {
    dispatch({ type: SWITCH_MENU_VISIBLE, payload: !showMenu });
    dispatch({ type: SWITCH_SIDEBAR_VISIBLE, payload: false });
  };

  const handleAlertMenuToggle = async (isOpen) => {
    if (isOpen) {
      const res = await getUnreadAlertList();
      if (res.status == 200) {
        setAlerts(res.data.result);
      }
    }
  }

  const handleClickAlert = async (id) => {
    const res = await setAlertsRead([id]);
    if (res.status == 200) {
      setAlertCount(alertCount - 1);
      setAlerts(alerts.filter(item => item._id != id));
    }
  }
  const handleClickAlertLocation = async (e, lat, lng, id) => {
    e.stopPropagation();
    await setAlertsRead([id]);
    navigate("/Dashboard", {
      state: {
        point: { lat, lng }
      }
    });
  }

  if (!isAuthenticated) {
    return <></>;
  }

  return (
    <div>
      {isMobile ? (
        <>
          {showMenu && (
            <div className="d-flex flex-column position-absolute menu-mobile" ref={menuRef}>
              <div className="logo-mobile d-flex align-items-center justify-content-center">
                <img
                  src="/assets/logo.png"
                  alt="none"
                  onClick={() => navigate("/Dashboard")}
                />
              </div>
              <div className="d-flex flex-column main">
                <div className="d-flex flex-column px-4 py-3">
                  <p className="text-white" style={{ fontSize: "2.25rem" }}>
                    {user?.fname}
                  </p>
                  <Button
                    className="ms-auto"
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={(event) => setAnchorEl(event.currentTarget)}
                  >
                    <div>
                      <img
                        crossOrigin='*'
                        src={user?.image ? process.env.REACT_APP_URL + "/" + user?.image : "/assets/common_user.png"}
                        alt="none"
                        className="person-icon"
                      />
                      <img
                        src="/assets/Polygon 2.svg"
                        alt="none"
                        className="polygon-header me-0"
                      />
                    </div>
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={() => setAnchorEl(null)}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem onClick={() => setAnchorEl(null)}>
                      <span style={{ fontSize: "14px" }}>Profile</span>
                    </MenuItem>
                    <MenuItem onClick={() => {
                      setAnchorEl(null);
                      navigate("/UpdateProfile");
                    }}>
                      <span style={{ fontSize: "14px" }}>My account</span>
                    </MenuItem>
                    <MenuItem onClick={handleLogout}>
                      <span style={{ fontSize: "14px" }}>Logout</span>
                    </MenuItem>
                    {isImpersonate() &&
                      <MenuItem onClick={handleDeimpersonate}>
                        <span style={{ fontSize: "14px" }}>Exit Impersonate</span>
                      </MenuItem>
                    }
                  </Menu>
                </div>
                <div className="horizontal-bar" />
                <div className="d-flex flex-column px-5 py-3">
                  {tabs
                    .filter((tab) =>
                      (user.role == UserRole.Admin || user.role == UserRole.Manager || !tab.onlyAdmin)
                    )
                    .map((tab, index) => (
                      <div key={index} className="tab my-4">
                        <NavLink
                          aria-current="page"
                          to={tab.to}
                          onClick={() =>
                            dispatch({
                              type: SWITCH_MENU_VISIBLE,
                              payload: false,
                            })
                          }
                          className="px-3 d-flex justify-content-start py-2"
                          style={({ isActive }) => ({
                            color: isActive ? "#FFFFFF" : "#005EEC",
                            backgroundColor: isActive ? "#1A2678" : "#FFFFFF",
                            borderRadius: "30px",
                          })}
                        >
                          <div className="d-flex content align-items-center">
                            <FontAwesomeIcon className="me-4" icon={tab.icon} />
                            <span className="mx-2">{tab.label}</span>
                          </div>
                        </NavLink>
                      </div>
                    ))}
                </div>
                <div
                  className="d-flex mt-auto text-white ms-auto me-5 mb-4 align-items-center"
                  onClick={handleLogout}
                >
                  <p
                    className="mb-0"
                    style={{ fontSize: "1.6rem", fontFamily: "rmedium" }}
                  >
                    Log Out
                  </p>
                  <img className="ms-3" src="/assets/Logout.svg" alt="none" />
                </div>
              </div>
            </div>
          )}
          <div
            className="position-absolute sidebar-icon d-flex justify-content-center align-items-center cursor-pointer"
            style={showMenu ? { left: "22.3rem" } : { left: "0.3rem" }}
            onClick={handleMobileSidebarControlClick}
          >
            <img src={showMenu ? "/assets/hambuger_select.svg" : "/assets/hambuger.svg"} alt="none" />
          </div>
          <Dropdown
            menu={{ alerts }}
            trigger={"click"}
            placement="bottomRight"
            onOpenChange={handleAlertMenuToggle}
            dropdownRender={(menu) => {
              return (
                <div className="alerts-menu-wrapper">
                  <div className="d-flex align-items-center justify-content-center gap-3 fs-2">
                    <img width={20} src="/assets/Bell2.svg" alt="none" />  Alerts
                  </div>
                  <div className="d-flex flex-column overflow-auto gap-2">
                    {alerts.map(item => (
                      <div key={item._id} className="alert-item" onClick={() => handleClickAlert(item._id)}>
                        <div className="d-flex align-items-center justify-content-betwee">
                          <span className="fs-4 me-3">{item.vehicleNo}</span>
                          <img src="/assets/car2.svg" alt="none" />
                          <span className="flex-grow-1"></span>
                          <img
                            src="/assets/Pointer.svg"
                            alt="none"
                            className="cursor-pointer me-4"
                            onClick={(e) => handleClickAlertLocation(e, item.lat, item.lng, item._id)}
                          />
                        </div>
                        <div className="alert-type">{item.type}</div>
                        <div className="text-end">{formatDateTimeSec(item.createdAt)}</div>
                      </div>
                    ))}
                  </div>
                  <div className="text-center">
                    <img src="/assets/down.svg" alt="none" style={{ height: ".8rem" }} className="cursor-pointer" />
                  </div>
                </div>
              );
            }}
          >
            <div className="position-absolute notifications-icon">
              <img width={20} src="/assets/Bell.svg" alt="none" />
              <div className="position-absolute text-center text-white">
                {alertCount}
              </div>
            </div>
          </Dropdown>
        </>
      ) : (
        <div className="header">
          <div className="upper py-1">
            <Button
              id="map-select-button"
              aria-controls={openMapSelect ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openMapSelect ? "true" : undefined}
              onClick={(event) => setAnchorMapSelectEl(event.currentTarget)}
            >
              <div>
                <img
                  src={mapMode === 1 ? "/assets/gmap.svg" : "/assets/mapbox.svg"}
                  alt="none"
                  className={mapMode === 1 ? "mx-2" : "mapbox-icon px-2"}
                />
                <img src="/assets/Polygon 2.svg" alt="none" className="mx-2" />
              </div>
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorMapSelectEl}
              open={openMapSelect}
              onClose={() => setAnchorMapSelectEl(null)}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={() => toggleMapMode(1)}>
                <img src="/assets/gmap.svg" alt="none" className="mx-auto" />
              </MenuItem>
              <div className="mx-3 horizontal-bar"></div>
              <MenuItem onClick={() => toggleMapMode(2)}>
                <img
                  src="/assets/mapbox.svg"
                  alt="none"
                  className="mapbox-icon px-2"
                />
              </MenuItem>
            </Menu>
            <Dropdown
              menu={{ alerts }}
              trigger={"click"}
              placement="bottomRight"
              onOpenChange={handleAlertMenuToggle}
              dropdownRender={(menu) => {
                return (
                  <div className="alerts-menu-wrapper">
                    <div className="d-flex align-items-center justify-content-center gap-3 fs-2">
                      <img width={20} src="/assets/Bell2.svg" alt="none" />  Alerts
                    </div>
                    <div className="d-flex flex-column overflow-auto gap-2">
                      {alerts.map(item => (
                        <div key={item._id} className="alert-item" onClick={() => handleClickAlert(item._id)}>
                          <div className="d-flex align-items-center justify-content-betwee">
                            <span className="fs-4 me-3">{item.vehicleNo}</span>
                            <img src="/assets/car2.svg" alt="none" />
                            <span className="flex-grow-1"></span>
                            <img
                              src="/assets/Pointer.svg"
                              alt="none"
                              className="cursor-pointer me-4"
                              onClick={(e) => handleClickAlertLocation(e, item.lat, item.lng, item._id)}
                            />
                          </div>
                          <div className="alert-type">{item.type}</div>
                          <div className="text-end">{formatDateTime(item.createdAt)}</div>
                        </div>
                      ))}
                    </div>
                    <div className="text-center">
                      <img src="/assets/down.svg" alt="none" style={{ height: ".8rem" }} className="cursor-pointer" />
                    </div>
                  </div>
                );
              }}
            >
              <Button style={{ width: "1.5rem", marginLeft: 5, marginRight: 10 }}>
                <div className="notifications-icon cursor-pointer">
                  <img width={20} src="/assets/Bell.svg" alt="none" />
                  <div className="position-absolute text-center text-white">
                    {alertCount}
                  </div>
                </div>
              </Button>
            </Dropdown>

            <p>{user?.fname}</p>
            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={(event) => setAnchorEl(event.currentTarget)}
            >
              <div>
                <img
                  crossOrigin='*'
                  src={user?.image ? process.env.REACT_APP_URL + "/" + user?.image : "/assets/common_user.png"}
                  alt="none"
                  className="person-icon"
                />
                <img
                  src="/assets/Polygon 2.svg"
                  alt="none"
                  className="polygon-header"
                />
              </div>
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={() => setAnchorEl(null)}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={() => setAnchorEl(null)}>
                <span style={{ fontSize: "14px" }}>Profile</span>
              </MenuItem>
              <MenuItem onClick={() => {
                setAnchorEl(null);
                navigate("/UpdateProfile");
              }}>
                <span style={{ fontSize: "14px" }}>My account</span>
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <span style={{ fontSize: "14px" }}>Logout</span>
              </MenuItem>
              {isImpersonate() &&
                <MenuItem onClick={handleDeimpersonate}>
                  <span style={{ fontSize: "14px" }}>Exit Impersonate</span>
                </MenuItem>
              }
            </Menu>
          </div>
          <div className="lower w-100 justify-content-between d-flex px-4 py-2">
            {tabs
              .filter((tab) => (user.role == UserRole.Admin || user.role == UserRole.Manager || !tab.onlyAdmin))
              .map((tab, index) => (
                <div key={index} className="tab">
                  <NavLink
                    aria-current="page"
                    to={tab.to}
                    className="px-3 d-flex justify-content-center py-2"
                    style={({ isActive }) => ({
                      color: isActive ? "#FFFFFF" : "#005EEC",
                      backgroundColor: isActive ? "#1A2678" : "#FFFFFF",
                      borderRadius: "30px",
                    })}
                  >
                    <div className="d-flex content align-items-center">
                      <FontAwesomeIcon className="me-4" icon={tab.icon} />
                      <span className="mx-2">{tab.label}</span>
                    </div>
                  </NavLink>
                </div>
              ))}
          </div>
          <Outlet />
        </div>
      )}
    </div>
  );
};

export default Header;
