import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { getResponse } from "../../../services/axios";

import "./AddDevice.css";
import { Select, notification } from "antd";
import PhoneNumberInput from "../../commons/input/PhoneNumberInput";
import { getDevices } from "../../../redux/actions/devices";
import { DeviceType } from "../../../common/common";
import { DeviceModels, Sensors } from "../../../utils/mockup";

const AddDevice = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [step, setStep] = useState(0);

  const [deviceModels, setDeviceModels] = useState([]);
  const [formData, setFormData] = useState({
    vehicleType: "Car",
    plate: "",
    imei: "",
    deviceType: "",
    model: "",
    sensors: [""],
    mobileNo: "",
    company: null,
  });

  useEffect(async () => {
    setDeviceModels(DeviceModels);
  }, []);

  const handleType = (deviceType) => {
    let models = [];
    if (deviceType == "") {
      models = DeviceModels;
    } else {
      models = DeviceModels.filter(item => item.type == deviceType);
    }
    setDeviceModels(models);
    let model = formData.model;
    if (deviceType != "" && model != "") {
      let device = DeviceModels.find(item => item.device == model);
      if (device.type != deviceType) {
        model = "";
      }
    }

    setFormData({ ...formData, deviceType: deviceType, sensors: [""], model: model });
  };

  const handleData = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleNumber = (value) => {
    setFormData({ ...formData, mobileNo: value });
  };

  const handleAddSensor = () => {
    setFormData({ ...formData, sensors: [...formData.sensors, ""] });
  }
  const handleRemoveSensor = (index) => {
    let sensors = [...formData.sensors];
    sensors.splice(index, 1);
    setFormData({ ...formData, sensors: sensors });
  }
  const handleUpdateSensor = (index, value) => {
    let sensors = [...formData.sensors];
    sensors[index] = value;
    setFormData({ ...formData, sensors: sensors });
  }

  const handleModelSelect = (model) => {
    let deviceType = formData.deviceType;
    let device = DeviceModels.find(item => item.device == model);
    if (device && device.type != deviceType) {
      deviceType = device.type;
      handleType(device.type);
    }
    setFormData({ ...formData, deviceType: deviceType, model: model });
  };

  const handleSubmit = () => {
    if (step === 0) {
      let msg = null;
      if (formData.vehicleType.length === 0) {
        msg = "Please select Vehicle type!";
      } else if (formData.plate.length === 0) {
        msg = "Please input Plate No!";
      } else if (formData.imei.length !== 15) {
        msg = "IMEI must be 15 digits!";
      } else if (formData.deviceType.length === 0) {
        msg = "Please select Device type!";
      } else if (formData.model.length === 0) {
        msg = "Please select Device model!";
      }

      if (msg != null) {
        notification.warning({
          description: msg,
        });
        return;
      }

      setStep(1);
      return;
    }

    final();
  };

  const final = async () => {
    const sendData = {
      vehicleType: formData.vehicleType,
      vehicleNo: formData.plate,
      deviceImei: formData.imei,
      mobileNo: formData.mobileNo,
      deviceType: formData.deviceType,
      deviceModel: formData.model,
      sensors: formData.sensors,
      company: formData.company,
    };
    var res = await getResponse("/api/vehicles/create", "post", sendData);
    if (res?.status == 200) {
      notification.success({
        description: "New device added successfully!",
      });
      dispatch(getDevices(false));
      setTimeout(() => {
        navigate("/Devices");
      }, 1000);
    }
  };

  return (
    <div className="add-device-main w-100 h-100">
      <p>Add Vehicle</p>
      <div className="add-device-div1 d-flex flex-column align-items-center p-5 mx-5">
        <div className="right-subsub1-sub1-add-device-div1 p-5 mb-4 d-flex flex-column align-items-center" style={{ width: "102rem", maxWidth: "100%" }}>
          <div className="step-wrapper mb-3">
            <div className={`rounded-circle step ${step >= 0 ? 'active' : ''}`}>Device</div>
            <div className="line"></div>
            <div className={`rounded-circle step ${step >= 1 ? 'active' : ''}`}>Sensors/<br />Accessories</div>
            <div className="line"></div>
            <div className={`rounded-circle step`}>Finish</div>
          </div>
          <div className={`${step === 0 ? 'd-flex' : 'd-none'} flex-column gap-3 `} style={{ minHeight: "51rem" }}>
            <div className="add-input-container d-flex flex-column">
              <label>Device IMEI</label>
              <input
                className="normal-input"
                value={formData.imei}
                name="imei"
                type="number"
                onChange={handleData}
                onInput={(e) => {
                  const value = e.target.value.slice(0, 15);
                  e.target.value = value.replace(/\D/, "");
                }}
              />
            </div>
            <div className="add-input-container d-flex flex-column">
              <label>Device Type</label>
              <select
                className="normal-input"
                value={formData.deviceType}
                name="deviceType"
                type="text"
                onChange={(e) => { handleType(e.target.value); }}
              >
                <option value={""}>Select Device Type</option>
                <option value={DeviceType.Teltonika}>{DeviceType.Teltonika}</option>
                <option value={DeviceType.Ruptela}>{DeviceType.Ruptela}</option>
              </select>
            </div>
            <div className="add-input-container d-flex flex-column">
              <label>Device Model</label>
              <Select
                showSearch
                placeholder="Select Model"
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={[{ label: "Select Device Model", value: "" }].concat(deviceModels.map((item) => ({
                  label: item.device, value: item.device
                })))}
                value={formData.model}
                onChange={(value) => handleModelSelect(value)}
              />
            </div>
            <div className="add-input-container d-flex flex-column">
              <label>SIM No.</label>
              <div className='input-item'>
                <PhoneNumberInput
                  value={formData.mobileNo}
                  onChange={handleNumber}
                />
              </div>
            </div>
            <div className="add-input-container d-flex flex-column position-relative">
              <label>Vehicle Type</label>
              <select
                className="normal-input"
                value={formData.vehicleType}
                name="vehicleType"
                onChange={handleData}
              >
                <option>Car</option>
                <option>Bike</option>
              </select>
              {formData.vehicleType === "Car" ? (
                <img className="vehicle-icon position-absolute" src="/assets/Car.svg" alt="none" />
              ) : formData.vehicleType === "Bike" ? (
                <img className="vehicle-icon position-absolute" src="/assets/Bike.svg" alt="none" />
              ) : <></>
              }
            </div>
            <div className="add-input-container d-flex flex-column">
              <label>Plate No.</label>
              <input
                className="normal-input"
                value={formData.plate}
                name="plate"
                type="text"
                maxLength={13}
                onChange={handleData}
              />
            </div>
            <div className="add-input-container d-flex flex-column">
              <label>Add to Company (Optional)</label>
              <select className='normal-input' name="company" type='text' value={formData.company} onChange={handleData}>
                <option value="">Select Company</option>
                {location?.state?.companies && location?.state?.companies.map(item => <option key={item._id} value={item._id}>{item.name}</option>)}
              </select>
            </div>
          </div>

          <div className={`${step === 1 ? 'd-flex' : 'd-none'} flex-column gap-3 `} style={{ minHeight: "51rem" }}>
            <p className="p-0 mt-3">External Sensors / Accessories</p>
            <div className="add-input-container d-flex flex-column">
              <select
                className="normal-input"
                value={formData.sensors?.[0]}
                name="sensor"
                type="text"
                onChange={(e) => handleUpdateSensor(0, e.target.value)}
              >
                <option value={""}>Select Sensor</option>
                {Sensors
                  .filter(item => item.type == formData.deviceType)
                  .filter(item => (!formData.sensors.includes(item.id) || item.id == formData.sensors[0]))
                  .map((item) => (
                    <option key={item.id} value={item.id}>{item.label}</option>
                  ))}
              </select>
            </div>

            {formData.sensors?.length > 1 &&
              formData.sensors?.filter((sensor, index) => index > 0).map((sensor, index) => (
                <div className={`add-input-container d-flex flex-column position-relative`} key={index}>
                  <select
                    className="normal-input"
                    value={sensor}
                    name="sensor"
                    type="text"
                    onChange={(e) => handleUpdateSensor(index + 1, e.target.value)}
                  >
                    <option value={""}>Select Sensor</option>
                    {Sensors
                      .filter(item => item.type == formData.deviceType)
                      .filter(item => (!formData.sensors.includes(item.id) || item.id == formData.sensors[index + 1]))
                      .map((item) => (
                        <option key={item.id} value={item.id}>{item.label}</option>
                      ))
                    }
                  </select>

                  <img src="/assets/removebtn2.svg" alt="none"
                    className="cursor-pointer position-absolute"
                    style={{ right: "-3rem", top: "50%", transform: "translateY(-50%)" }}
                    onClick={() => handleRemoveSensor(index + 1)} />
                </div>
              ))
            }

            <div className="add-input-container mt-4">
              <button className="tab-button" onClick={handleAddSensor} style={{ borderRadius: ".5rem", padding: "0.4rem 0", minWidth: "10rem" }}>Add</button>
            </div>
          </div>
        </div>
        <div className="sub5-right-subsub1-sub1-add-device-div1 d-flex justify-content-center w-100">
          <button className='me-5' onClick={() => navigate("/Devices")}>Cancel</button>
          <button onClick={() => handleSubmit()}>
            {step === 1 ? 'Submit' : 'Next'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddDevice;
