import React, { useEffect, useState } from "react";

import "./acs.css";
import { sendCommandMsg, socket } from "../../socket";
import { formatDateTimeSec, getPacketDateValue, getPacketIntValue, getPacketStrValue, getStringFromBytes, sleep } from "../../utils/globals";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

const TerminalStatus = () => {

  const isMobile = useMediaQuery("(max-width: 768px)");

  const location = useLocation();
  const selectedVehData = location.state;

  const [systemTime, setSystemTime] = useState("");
  const [ipAddr, setIpAddr] = useState("");
  const [subnetAddr, setSubnetAddr] = useState("");
  const [gatewayAddr, setGatewayAddr] = useState("");
  const [dnsAddr, setDnsAddr] = useState("");
  const [listenPort, setListenPort] = useState(0);
  const [softwareIp, setSoftwareIp] = useState("");
  const [nextIp, setNextIp] = useState("");
  const [wgReader, setWGReader] = useState("");
  const [workingMode, setWorkingMode] = useState("");
  const [antiPassBackStatus, setAntiPassBackStatus] = useState("");
  const [antiPassBackTime, setAntiPassBackTime] = useState("");
  const [accessLogCount, setAccessLogCount] = useState("");

  useEffect(() => {
    if (selectedVehData) {
      loadData();
    }

    const onUpdateCommandResponse = async (cmdData) => {
      // console.log(cmdData);

      const { deviceImei, matchingId, response } = cmdData;
      if (deviceImei != selectedVehData.deviceImei) {
        return;
      }
      if (matchingId == '10') {
        let dt = getPacketDateValue(response, 0);
        setSystemTime(formatDateTimeSec(dt));
      } else if (matchingId == '17') {
        let paramId = getPacketStrValue(response, 0, 1);
        let temp = 0;
        switch (paramId) {
          case '02':
            temp = getPacketIntValue(response, 1, 1);
            setWGReader(temp == 0 ? 'WG 26' : 'WG 34');
            break;
          case '03':
            setIpAddr(`${getPacketIntValue(response, 1, 1)}.${getPacketIntValue(response, 2, 1)}.${getPacketIntValue(response, 3, 1)}.${getPacketIntValue(response, 4, 1)}`);
            break;
          case '04':
            setSubnetAddr(`${getPacketIntValue(response, 1, 1)}.${getPacketIntValue(response, 2, 1)}.${getPacketIntValue(response, 3, 1)}.${getPacketIntValue(response, 4, 1)}`);
            break;
          case '05':
            setGatewayAddr(`${getPacketIntValue(response, 1, 1)}.${getPacketIntValue(response, 2, 1)}.${getPacketIntValue(response, 3, 1)}.${getPacketIntValue(response, 4, 1)}`);
            break;
          case '06':
            setDnsAddr(`${getPacketIntValue(response, 1, 1)}.${getPacketIntValue(response, 2, 1)}.${getPacketIntValue(response, 3, 1)}.${getPacketIntValue(response, 4, 1)}`);
            break;
          case '08':
            setListenPort(getPacketIntValue(response, 1, 2));
            break;
          case '09':
            setAntiPassBackStatus(getPacketIntValue(response, 1, 1) == 0 ? 'Disabled' : 'Enabled');
            setAntiPassBackTime(getPacketIntValue(response, 2, 2));
            break;
          case '0e':
            setSoftwareIp(getStringFromBytes(getPacketStrValue(response, 1)));
            break;
          case '0f':
            setNextIp(`${getPacketIntValue(response, 1, 1)}.${getPacketIntValue(response, 2, 1)}.${getPacketIntValue(response, 3, 1)}.${getPacketIntValue(response, 4, 1)}`);
            break;
          case '11':
            temp = getPacketIntValue(response, 1, 1);
            setWorkingMode(temp == 0 ? 'Controller' : temp == 1 ? 'Sub-Controller' : 'Dummy Reader');
            break;
        }
      } else if (matchingId == '40') {
        setAccessLogCount(getPacketIntValue(response, 0, 4));
      }
    }
    socket.on('updateCommandResponse', onUpdateCommandResponse);

    return () => {
      socket.off('updateCommandResponse', onUpdateCommandResponse);
    };
  }, [selectedVehData]);

  const loadData = async () => {
    const interval = 200;
    const deviceImei = selectedVehData.deviceImei;
    sendCommandMsg(null, deviceImei, "10");
    await sleep(interval);
    sendCommandMsg(null, deviceImei, "17:02");
    await sleep(interval);
    sendCommandMsg(null, deviceImei, "17:03");
    await sleep(interval);
    sendCommandMsg(null, deviceImei, "17:04");
    await sleep(interval);
    sendCommandMsg(null, deviceImei, "17:05");
    await sleep(interval);
    sendCommandMsg(null, deviceImei, "17:06");
    await sleep(interval);
    sendCommandMsg(null, deviceImei, "17:08");
    await sleep(interval);
    sendCommandMsg(null, deviceImei, "17:09");
    await sleep(interval);
    sendCommandMsg(null, deviceImei, "17:0E");
    await sleep(interval);
    sendCommandMsg(null, deviceImei, "17:0F");
    await sleep(interval);
    sendCommandMsg(null, deviceImei, "17:11");
    await sleep(interval);
    sendCommandMsg(null, deviceImei, "40");
  }

  return (
    <div className="acs-panel">
      <div className="panel-title">
        {selectedVehData.vehicleName} {'>'} Terminal Status
      </div>

      <div className="panel-body" style={{ backgroundColor: "white", padding: isMobile ? "2rem" : "4rem 6rem" }}>
        <div className="row h-100" style={{ gap: isMobile ? "1rem" : "4rem" }}>
          <div className="col-md d-flex flex-column justify-content-between gap-3">
            <div className="row info-item gap-4">
              <div className="col">Product Name</div>
              <div className="col">{selectedVehData?.vehicleName}</div>
            </div>
            <div className="row info-item gap-4">
              <div className="col">Terminal ID(MAC Address)</div>
              <div className="col">{selectedVehData?.tid}({selectedVehData?.deviceImei})</div>
            </div>
            <div className="row info-item gap-4">
              <div className="col">Serial No.</div>
              <div className="col">{selectedVehData?.sn}({parseInt(selectedVehData?.sn, 16)})</div>
            </div>
            <div className="row info-item gap-4">
              <div className="col">Firmware Version</div>
              <div className="col">{selectedVehData?.FW_Version}</div>
            </div>
            <div className="row info-item gap-4">
              <div className="col">System Time</div>
              <div className="col">{systemTime}</div>
            </div>
            <div className="row info-item gap-4">
              <div className="col">Registered Users</div>
              <div className="col">{selectedVehData?.registeredUser}/{selectedVehData?.maxUserCapacity - selectedVehData?.registeredUser}</div>
            </div>
            <div className="row info-item gap-4">
              <div className="col">Registered FP</div>
              <div className="col"></div>
            </div>
            <div className="row info-item gap-4">
              <div className="col">Access Log Count</div>
              <div className="col">{accessLogCount}</div>
            </div>
          </div>

          <div className="col-md d-flex flex-column justify-content-between gap-3">
            <div className="row info-item gap-4">
              <div className="col">IP Address</div>
              <div className="col">{ipAddr}</div>
            </div>
            <div className="row info-item gap-4">
              <div className="col">Subnet mask</div>
              <div className="col">{subnetAddr}</div>
            </div>
            <div className="row info-item gap-4">
              <div className="col">Default Gateway</div>
              <div className="col">{gatewayAddr}</div>
            </div>
            <div className="row info-item gap-4">
              <div className="col">Primary DNS</div>
              <div className="col">{dnsAddr}</div>
            </div>
            <div className="row info-item gap-4">
              <div className="col">Listen Port/Software IP(status)</div>
              <div className="col">{listenPort} / {softwareIp}</div>
            </div>
            <div className="row info-item gap-4">
              <div className="col">Anti-Pass-Back(Tolerance Timer)</div>
              <div className="col">{antiPassBackStatus}({antiPassBackTime})</div>
            </div>
            <div className="row info-item gap-4">
              <div className="col">Next Biosense(Status)</div>
              <div className="col">{nextIp}</div>
            </div>
            <div className="row info-item gap-4">
              <div className="col">Control Mode</div>
              <div className="col">{workingMode}({wgReader})</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TerminalStatus;
