import React, { useState, useEffect, useRef } from "react";
import { useMediaQuery } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { useBottomScrollListener } from "react-bottom-scroll-listener";

import { getAlertList, getCompanies } from "../../services/axios";
import { formatDateTimeSec, getGooglePosUrl } from "../../utils/globals";
import { Input, message, Select } from "antd";

import "./Alerts.css";
import CustomDatePicker from "../commons/datepicker/CustomDatePicker";
import { useSelector } from "react-redux";
import { socket } from "../../socket";
import { RuleTypes } from "../../common/common";
import { debounce } from "lodash";

const Alerts = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");

  const devices = useSelector((state) => state.devicesList.devices);

  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setIsLoading] = useState(false);

  const [dataList, setDataList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [companies, setCompanies] = useState([]);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchPlateNo, setSearchPlateNo] = useState(null);
  const [searchAlertType, setSearchAlertType] = useState(null);
  const [searchImeiText, setSearchImeiText] = useState("");
  const [searchCompanyText, setSearchCompanyText] = useState(null);

  const [searchFold, setSearchFold] = useState(true);


  const getList = async (page = 1) => {
    setIsLoading(true);
    var res = await getAlertList({
      pageNum: page,
      pageSize: pageSize,
      fromDate: startDate,
      toDate: endDate,
      alertType: searchAlertType,
      plateNo: searchPlateNo,
      company: searchCompanyText,
    });
    if (page === 1) {
      setDataList(res?.data?.result);
    } else {
      setDataList([
        ...dataList,
        ...res?.data?.result
      ]);
    }
    setTotalCount(res?.data?.totalCount);
    setPageNum(page);
    setIsLoading(false);
  }

  useEffect(() => {
    getCompanies()
      .then(res => {
        if (res.status == 200) {
          setCompanies(res.data.result);
        }
      })
      .catch(err => { });


    function onAlertOccured(data) {
      getList();
    }
    socket.on('alertOccured', onAlertOccured);

    return () => {
      socket.off('alertOccured', onAlertOccured);
    }
  }, []);

  useEffect(() => {
    getList(1);
  }, [searchAlertType, searchPlateNo, startDate, endDate, searchCompanyText]);

  useEffect(() => {
    if (isLoading) {
      messageApi.open({
        type: 'loading',
        content: 'Loading Data ..',
        duration: 0,
      });
    } else {
      messageApi.destroy();
    }
  }, [isLoading]);

  const scrollRef = useRef();
  // const scrollRef = useBottomScrollListener(() => {
  //   (async () => {
  //     console.log("here");
  //     const totalPage = Math.ceil(totalCount / pageSize);
  //     if (totalPage > pageNum) {
  //       await getList(pageNum + 1);
  //     }
  //   })();
  // });

  useEffect(() => {

    const onScroll = debounce(async () => {
      const pos = scrollRef?.current.scrollTop + scrollRef?.current.clientHeight;
      const height = scrollRef?.current.scrollHeight;
      // console.log("🚀 ~ onScroll ~ pos:", pos, height)
      if (pos >= height - 20) {
        (async () => {
          const totalPage = Math.ceil(totalCount / pageSize);
          if (totalPage > pageNum) {
            await getList(pageNum + 1);
          }
        })();
      }
    }, 200);

    scrollRef?.current?.addEventListener("scroll", onScroll);
    document.body.addEventListener('touchmove', onScroll);
    return () => {
      scrollRef?.current?.removeEventListener("scroll", onScroll);
      document.body.removeEventListener('touchmove', onScroll);
    }
  }, [scrollRef, pageNum, totalCount])



  const handlePlate = (val) => {
    setSearchPlateNo(val);
  };

  const handleImei = (event) => {
    const val = event.target.value;
    setSearchImeiText(val);
  };

  const handleAlert = (val) => {
    setSearchAlertType(val);
  };

  const handleCompany = (val) => {
    setSearchCompanyText(val);
  };

  const handleClear = () => {
    setSearchPlateNo(null);
    setSearchAlertType(null);
    setSearchCompanyText(null);
    setStartDate("");
    setEndDate("");
  };

  return (
    <div className="alerts-main w-100 h-100">
      {contextHolder}

      {!isMobile ? (
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="sub1-div1 d-flex justify-content-between align-items-center w-100">
            <p className="px-5 text-white d-flex justify-content-center align-items-center">
              Total Alerts <span className="ms-3">{totalCount}</span>
            </p>
            <div className="d-flex">
              <div className="tab-button d-flex justify-content-center mx-1 align-items-center">
                <img src="/assets/export.svg" alt="none" />
                <button className="ms-2">Export</button>
              </div>
            </div>
          </div>
          <div className="sub2-div1 d-flex flex-column justify-content-end align-items-end w-100">
            <div className="subsub1-sub2-div1 bg-white d-flex flex-column py-2 w-100">
              <div className="sub1-subsub1-sub2-div1 d-flex align-items-center my-2">
                <img src="/assets/Searchwithperson.svg" alt="none" className="search-icon-content" />
                <span>Search Alert</span>
              </div>
              <div className="sub2-subsub1-sub2-div1 d-flex justify-content-between align-items-center">
                <Select
                  showSearch
                  allowClear
                  className="field-input"
                  placeholder="Plate No./Device"
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={devices?.map((item) => ({
                    label: `${item.vehicleName}`, value: item.vehicleName
                  }))}
                  value={searchPlateNo}
                  onChange={(value) => handlePlate(value)}
                />
                <Select
                  allowClear
                  className="field-input"
                  placeholder="Alert Type"
                  options={Object.values(RuleTypes).map((type, index) => ({
                    label: type, value: type
                  }))}
                  value={searchAlertType}
                  onChange={(value) => handleAlert(value)}
                />
                <div className="field-input date-picker-input">
                  <CustomDatePicker
                    placeholderText="From"
                    selectedDate={startDate}
                    onChange={(date) => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    maxDate={endDate}
                  />
                  <img src="/assets/calender.png" alt="cal" />
                </div>
                <div className="field-input date-picker-input">
                  <CustomDatePicker
                    placeholderText="To"
                    selectedDate={endDate}
                    onChange={(date) => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    maxDate={new Date()}
                    maxTime={new Date()}
                  />
                  <img src="/assets/calender.png" alt="cal" />
                </div>
                <Select
                  allowClear
                  className="field-input"
                  placeholder="Company"
                  options={companies.map((item, index) => ({
                    label: item.name, value: item._id
                  }))}
                  value={searchCompanyText}
                  onChange={(value) => handleCompany(value)}
                />
                <div
                  className="tab-button d-flex justify-content-center align-items-center px-4 ms-4"
                  onClick={() => handleClear()}
                >
                  <img src="/assets/clear.svg" alt="none" />
                  <button>Clear</button>
                </div>
              </div>
            </div>
            <div className="div2">
              <div className="subsub1-sub1-alerts-div2 py-3">
                <p className="mb-0 text-start ps-5">Plate No./Device</p>
                <p className="mb-0 text-start">Alert Type</p>
                <p className="mb-0 text-start">Location</p>
                <p className="mb-0 text-start">Time</p>
                <p className="mb-0 text-start">Company</p>
              </div>
              <div className="sub2-div2 overflow-auto" ref={scrollRef}>
                {dataList?.map((item, index) => {
                  return (
                    <div key={index} id="import-alerts">
                      <p id="sub1-import-alerts">{index + 1}</p>
                      <div className="subsub1-sub2-alerts-div2">
                        <p className="item ps-5">{item.vehicleNo}</p>
                        <p className="item">{item.type}</p>
                        <div className="item text-start flex-column align-items-start">
                          <a
                            href={getGooglePosUrl(item.lat, item.lng)}
                            target="_blank"
                            className="sub1-import-gps-path-alerts"
                            style={{ fontSize: "1.6rem", textDecoration: "none", color: "#1A3096" }}
                            rel="noreferrer"
                          >
                            {item.lat}, {item.lng}
                          </a>
                          <p>{item.address}</p>
                        </div>
                        <p className="item">{formatDateTimeSec(item.createdAt)}</p>
                        <p className="item">{item.company}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="tab mobile-title mb-4">
            <div
              aria-current="page"
              className="px-3 d-flex justify-content-center py-2"
              style={{
                color: "white",
                backgroundColor: "#1A2678",
                borderRadius: "30px",
              }}
            >
              <div className="d-flex content align-items-center">
                <FontAwesomeIcon className="me-4" icon={faBell} />
                Alerts
              </div>
            </div>
          </div>
          <div className="sub1-div1 d-flex justify-content-between align-items-end w-100 p-0 my-3">
            <p className="px-3 text-white d-flex justify-content-evenly align-items-center mb-0">
              Total Alerts <span className="ms-3">{totalCount}</span>
            </p>
            <div className="d-flex">
              <div className="tab-button d-flex justify-content-evenly mx-1 align-items-center">
                <img src="/assets/export.svg" alt="none" />
                <button>Export</button>
              </div>
            </div>
          </div>
          <div className="subsub1-sub2-div1 bg-white d-flex flex-column py-2 px-4 w-100">
            <div className="sub1-subsub1-sub2-div1 d-flex align-items-center p-2" onClick={() => setSearchFold(!searchFold)}>
              <img src="/assets/Searchwithperson.svg" alt="none" className="search-icon-content" />
              <span>Search Alert</span>
              <img
                className="ms-auto accordion"
                style={!searchFold ? { transform: "rotate(180deg)" } : { transform: "none" }}
                src="/assets/arrow-down.png"
                alt="Arrow Down"
              />
            </div>
            {!searchFold && (
              <div className="sub2-subsub1-sub2-div1 d-flex flex-column px-0">
                <div className="d-flex w-100">
                  <div className="col-6 px-1">
                    <Select
                      showSearch
                      allowClear
                      className="field-input"
                      placeholder="Plate No./Device"
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={devices?.map((item) => ({
                        label: `${item.vehicleName}`, value: item.vehicleName
                      }))}
                      value={searchPlateNo}
                      onChange={(value) => handlePlate(value)}
                    />
                  </div>
                  <div className="col-6 px-1">
                    <Select
                      allowClear
                      className="field-input"
                      placeholder="Alert Type"
                      options={Object.values(RuleTypes).map((type, index) => ({
                        label: type, value: type
                      }))}
                      value={searchAlertType}
                      onChange={(value) => handleAlert(value)}
                    />
                  </div>
                </div>
                <div className="d-flex w-100">
                  <div className="col-6 px-1">
                    <div className="field-input date-picker-input">
                      <CustomDatePicker
                        placeholderText="From"
                        selectedDate={startDate}
                        onChange={(date) => setStartDate(date)}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        maxDate={endDate}
                      />
                      <img src="/assets/calender.png" alt="cal" />
                    </div>
                  </div>
                  <div className="col-6 px-1">
                    <div className="field-input date-picker-input">
                      <CustomDatePicker
                        placeholderText="To"
                        selectedDate={endDate}
                        onChange={(date) => setEndDate(date)}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        maxDate={new Date()}
                        maxTime={new Date()}
                      />
                      <img src="/assets/calender.png" alt="cal" />
                    </div>
                  </div>
                </div>
                <div className="d-flex w-100">
                  <div className="col-6 px-1">
                    <input
                      className="field-input"
                      type="numder"
                      placeholder="IMEI"
                      value={searchImeiText}
                      onChange={handleImei}
                    />
                  </div>
                  <div className="col-6 px-1">
                    <Select
                      allowClear
                      className="field-input"
                      placeholder="Company"
                      options={companies.map((item, index) => ({
                        label: item.name, value: item._id
                      }))}
                      value={searchCompanyText}
                      onChange={(value) => handleCompany(value)}
                    />
                  </div>
                </div>
                <div
                  className="tab-button d-flex justify-content-center align-items-center px-4 ms-auto py-1"
                  onClick={() => handleClear()}
                >
                  <img src="/assets/clear.svg" alt="none" />
                  <button>Clear</button>
                </div>
              </div>
            )}
          </div>
          <div className={`sub2-div2 px-4 w-100 overflow-auto ms-0 ${searchFold && "folded"}`} ref={scrollRef}>
            <div className={`d-flex flex-column`}>
              {dataList?.map((item, index) => {
                return (
                  <div id="import-alerts" key={index}>
                    <p id="sub1-import-alerts">{index + 1}</p>
                    <div className="subsub1-sub2-alerts-div2 d-flex flex-column align-items-center py-2 px-3">
                      <div className="d-flex w-100">
                        <p className="mb-0 px-2 col-4 text-white">Plate No./Device</p>
                        <p className="mb-0 px-2 col-8 item justify-content-start">
                          {item?.vehicleNo}
                        </p>
                      </div>
                      <div className="d-flex w-100">
                        <p className="mb-0 px-2 col-4 text-white">Alert Type</p>
                        <p className="mb-0 px-2 col-8 item justify-content-start">
                          {item?.type}
                        </p>
                      </div>
                      <div className="d-flex w-100">
                        <p className="mb-0 px-2 col-4 text-white">Location</p>
                        <div className="text-center mb-0 px-2 col-8 item justify-content-start flex-column align-items-start">
                          <a
                            href={getGooglePosUrl(item.lat, item.lng)}
                            target="_blank"
                            className="sub1-import-gps-path-alerts"
                            style={{ textDecoration: "none", color: "rgb(212 227 208)" }}
                            rel="noreferrer"
                          >
                            {item.lat}, {item.lng}
                          </a>
                          <p className="mb-0">{item.address}</p>
                        </div>
                      </div>
                      <div className="d-flex w-100">
                        <p className="mb-0 px-2 col-4 text-white">Time</p>
                        <p className="mb-0 px-2 col-8 item justify-content-start">
                          {formatDateTimeSec(item.createdAt)}
                        </p>
                      </div>
                      <div className="d-flex w-100">
                        <p className="mb-0 px-2 col-4 text-white">Company</p>
                        <p className="mb-0 px-2 col-8 item justify-content-start">
                          {item.company}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Alerts;
