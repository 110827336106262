import React, { useEffect, useState } from "react";
import { formatDateTime } from "../../../utils/globals";
import { CommandOperands, CommandPriorities, cmdType, getCommandPhones } from "../../../common/common";
import { getCommandSettings } from "../../../services/axios";
import { commandSetting } from "../../../services/axios";

import CommandSettingPanel from "../../commons/panel/CommandSettingPanel";
import Switch from "../../commons/switch/Switch";
import SelectBox from "../../commons/input/SelectBox";
import TextInput from "../../commons/input/TextInput";
import Try from "../try/Try";
import { sendCommandMsg } from "../../../socket";

const PanelAxisY = ({token, deviceImei, commandResponse, gsmNumbers}) => {

  const [isOn, setIsOn] = useState(false);
  const [priority, setPriority] = useState("");
  const [operand, setOperand] = useState("");
  const [highLevel, setHighLevel] = useState(0);
  const [lowLevel, setLowLevel] = useState(0);
  const [eventOnly, setEventOnly] = useState("");
  const [average, setAverage] = useState(0);
  const [sendSms, setSendSms] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(0);
  const [smsText, setSmsText] = useState("");

  const [updatedDate, setUpdatedDate] = useState("")


  const sendDigitalAxisY = async () => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.AxisY,
      params: {
        priority: priority,
        operand: operand,
        highLevel: highLevel,
        lowLevel: "0",
        eventOnly: eventOnly,
        average: average,
        sendSms: sendSms ? "1" : "0",
        phoneNo: sendSms ? phoneNumber : "0",
        sms: sendSms ? smsText : ""
      },
      devImei: deviceImei
    }

    var res = await commandSetting(sendCommandData);
    if (res.status === 200) {
      sendCommandMsg(res.data.commandId);
    }
  }

  const refreshAxisY = async (isLast = false) => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.AxisY,
      devImei: deviceImei,
      isLast: isLast,
    }
    var res = await getCommandSettings(sendCommandData);
    if (res.status === 200) {
      if (isLast) {
        setResponse(res.data);
      } else {
        sendCommandMsg(res.data.commandId);
      }
    }
  }

  const setResponse = (response) => {
    setUpdatedDate(formatDateTime(response.updatedAt));
    setPriority(response.priority);
    setOperand(response.operand);
    setHighLevel(response.highLevel);
    setLowLevel(response.lowLevel);
    setEventOnly(response.eventOnly);
    setAverage(response.average);
    setSendSms(parseInt(response.phoneNo) > 0);
    setPhoneNumber(response.phoneNo);
    setSmsText(response.sms);
  }

  useEffect(() => {
    if (commandResponse?.cmdType == cmdType.AxisY) {
      setResponse(commandResponse);
    }
  }, [commandResponse]);

  useEffect(() => {
    refreshAxisY(true);
  }, [deviceImei]);

  return (
    <CommandSettingPanel
      title="Axis Y"
      description="Axis Y parameters settings"
      date={updatedDate}
      onSendAction={sendDigitalAxisY}
      onRefreshAction={refreshAxisY}
      content={(
        <div className="d-flex flex-column align-items-center py-3 px-5">
          <div className="input-element border-0 d-flex justify-content-center mb-3">
            <Switch
              value={isOn}
              onChange={(checked) => setIsOn(checked)}
              prefix={"OFF"}
              suffix={"ON"}
            />
          </div>
          <SelectBox
            name="Priority"
            value={priority}
            onChange={(e) => setPriority(e.target.value)}
            options={CommandPriorities}
            tooltip={"Priority of Axis Y element"}
          />
          <SelectBox
            name="Operand"
            value={operand}
            onChange={(e) => setOperand(e.target.value)}
            options={CommandOperands}
            tooltip={"Operand of Axis Y element"}
          />
          <TextInput
            name="High Level"
            tooltip="High level of Axis Y value range"
            type="number"
            options={{ min: 0, max: 1 }}
            value={highLevel}
            onChange={(value) => setHighLevel(value < 1 ? 0 : 1)} />
          <TextInput
            name="Low Level"
            tooltip="Low level of Axis Y value range"
            type="number"
            options={{ disabled: true }}
            value={lowLevel}
            onChange={(value) => setLowLevel(value)} />
          <SelectBox 
            name="Event Only"
            value={eventOnly}
            onChange={(e) => setEventOnly(e.target.value)}
            options={[
              { value: "", label: "" },
              { value: "0", label: "Disable" },
              { value: "1", label: "Enable" },
              { value: "2", label: "Crash" },
            ]}
            tooltip={"Send Axis Y element either when event happens or in each record"}
            />
          <TextInput
            name="Average"
            tooltip="Axis Y average"
            type="number"
            options={{ min: 0, max: 65535 }}
            value={average}
            onChange={(value) => setAverage(value)} />
          <div className="input-element border-0 w-100 position-relative d-flex justify-content-start mb-3" style={{ paddingLeft: '8rem' }}>
            <Switch
              value={sendSms}
              onChange={(checked) => setSendSms(checked)}
              prefix={<span className="pe-5 me-5">Send SMS</span>}
              suffix={"ON"}
            />
            <div className="position-absolute" style={{ right: '0' }}>
              <Try text={"Enable sending SMS of Axis Y event"} />
            </div>
          </div>
          {sendSms && (
            <>
              <SelectBox
                name="Phone Number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                options={getCommandPhones(gsmNumbers)}
              />
              <TextInput
                name="SMS Text"
                type="text"
                value={smsText}
                onChange={(value) => setSmsText(value)} />
            </>
          )}
        </div>
      )} />
  );
};

export default PanelAxisY;
