import { useEffect } from "react";
import ReactLoading from "react-loading";


const LoadingSpinner = ({ visible = false, global = true, timeout = 30, onTimeout = () => { } }) => {

    useEffect(() => {
        if (!visible || timeout == 0) {
            return;
        }

        const timer = setTimeout(() => {
            onTimeout();
        }, timeout * 1000);

        return () => {
            clearTimeout(timer);
        }
    }, [visible, timeout]);

    return (
        <>
            {visible &&
                <div
                    className={`${global ? 'position-fixed' : 'position-absolute'} w-100 h-100 top-0 start-0 d-flex align-items-center justify-content-center`}
                    style={{ backgroundColor: "#00000080", zIndex: 50 }}>
                    <ReactLoading type="spinningBubbles" color="#fff" />
                </div>
            }
        </>
    );
}

export default LoadingSpinner;
