import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, Modal, notification } from 'antd';
import { getDevices } from '../../../redux/actions/devices';
import { showConfirmEx } from '../../../utils/globals';
import Search from 'antd/es/input/Search';
import { addRuleVehicles } from '../../../services/axios';

const AssignVehicleRule = ({ isShowModal, onClose, rule }) => {

    const dispatch = useDispatch();
    const devices = useSelector((state) => state.devicesList.devices);
    const [devicesData, setDevicesData] = useState(devices);

    const [checkAll, setCheckAll] = useState(false);
    const [checkedList, setCheckedList] = useState([]);
    const [keyword, setKeyword] = useState("");

    useEffect(() => {
        setDevicesData(devices);
    }, [devices]);

    useEffect(() => {
        if (rule?.deviceImeis?.length > 0) {
            setCheckedList(rule?.deviceImeis);
        } else {
            setCheckedList(devices.map(item => item.deviceImei));
        }
    }, [rule]);

    const handleSearch = (e) => {
        setKeyword(e.target.value);
    }

    const handleCheck = (checked, item) => {
        const checkedDevices = [...checkedList];
        const index = checkedDevices.findIndex(d => d == item.deviceImei);
        if (index != -1) {
            checkedDevices.splice(index, 1);
        }
        if (checked) {
            checkedDevices.push(item.deviceImei);
        }
        setCheckedList(checkedDevices);
    }

    const handleAssign = async () => {
        showConfirmEx(`Are You sure to Add selected vehicles to rule ${rule?.name}?`)
            .then(async () => {
                const res = await addRuleVehicles({
                    deviceImeis: checkedList,
                    id: rule?._id
                });
                if (res.status == 200) {
                    notification.success({
                        description: "Add successfully!",
                    });
                    onClose(true);
                    dispatch(getDevices(false));
                } else {
                    notification.error({
                        description: "Something went wrong!",
                    });
                }
            })
            .catch(() => {
            });
    }

    return (
        <Modal
            title="Add Vehicles"
            centered
            open={isShowModal}
            onOk={() => onClose()}
            onCancel={() => onClose()}
            width={800}
            style={{ maxWidth: "100%" }}
            className='assing-vehicle-modal'
            footer={false}
        >
            <div className='pt-4'>
                <div className='mb-3'>
                    <Search
                        size='large'
                        className='w-50'
                        placeholder='Plate No, IMEI No.'
                        onChange={handleSearch}
                    />
                </div>
                <div className="list-header py-3">
                    <p className='text-center'>
                        {/* <Checkbox /> */}
                    </p>
                    <p>Plate No.</p>
                    <p>Device</p>
                    <p>Model</p>
                    <p>IMEI No.</p>
                </div>
                <div className="list-body overflow-auto" style={{ minHeight: '25rem' }}>
                    {devicesData
                        ?.filter(item => item.deviceImei?.toLocaleLowerCase().includes(keyword.toLocaleLowerCase())
                            || item.vehicleName?.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()))
                        ?.map((item, index) => (
                            <div key={index}>
                                <p className='text-center'>
                                    <Checkbox
                                        checked={checkedList?.includes(item?.deviceImei)}
                                        onChange={(e) => handleCheck(e.target.checked, item)}
                                    />
                                </p>
                                <p>{item?.vehicleName}</p>
                                <p>{item?.deviceType}</p>
                                <p>{item?.deviceModel}</p>
                                <p>{item?.deviceImei}</p>
                            </div>
                        ))}
                </div>
                <div className='mt-4 d-flex justify-content-end footer'>
                    <button className='d-flex align-items-center' onClick={handleAssign}><img src="/assets/addbtn.svg" alt="none" className='me-3' /> Add to Rule</button>
                </div>
            </div>
        </Modal>
    )
}

export default AssignVehicleRule;
