import axios from "axios";

if (process.env.REACT_APP_DEV_MODE === "FALSE") {
  axios.defaults.baseURL = process.env.REACT_APP_URL;
} else {
  axios.defaults.baseURL = process.env.REACT_APP_DEV_URL;
}

axios.interceptors.request.use(function (config) {
  // document.body.classList.add('loading-indicator');
  return config
}, function (error) {
  return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
  document.body.classList.remove('loading-indicator');
  return response;
}, function (error) {
  document.body.classList.remove('loading-indicator');
  return Promise.reject(error);
});

const getUserNameList = async (props) => {
  try {
    var result = await axios.post("/auth/userIdList/", props, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    return error.response;
  }
};

const signInUser = async (props) => {
  try {
    var result = await axios.post("/auth/login/", props, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return result;
  } catch (error) {
    return error.response;
  }
};
const signInUserAs = async (props) => {
  try {
    var result = await axios.post("/api/loginAs", props, {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    });
    return result;
  } catch (error) {
    return error.response;
  }
};

const resendVerifyEmail = async ({ email }) => {
  try {
    var result = await axios.post(`/auth/resendEmail`, {
      email,
    });
    if (result) {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

const userRegister = async (props) => {
  try {
    var result = await axios.post("/auth/signUp/", props, {
      headers: { "Content-Type": "application/json" },
    });
    return result;
  } catch (error) {
    return error.response;
  }
};

const resetPassword = async (props) => {
  try {
    var result = await axios.post("/auth/reset/", props, {
      headers: { "Content-Type": "application/json" },
    });
    return result;
  } catch (error) {
    return error.response;
  }
};

const getHistoryList = async (props) => {
  try {
    var result = await axios.post("/api/vehicles/history", props, {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    });
    return result;
  } catch (error) {
    return error.response;
  }
};

const getHistoryDates = async (props) => {
  try {
    var result = await axios.post("/api/vehicles/history-dates", props, {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    });
    return result;
  } catch (error) {
    return error.response;
  }
};

const getUser = async (props) => {
  try {
    var result = await axios.post("/api/users/getUser", props, {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    });
    return result.data;
  } catch (error) {
    return error.response;
  }
};

const activateUser = async (props) => {
  try {
    var result = await axios.post("/api/users/activateUser", props, {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    });
    return result;
  } catch (error) {
    return error.response;
  }
};

const getUsers = async () => {
  try {
    var result = await axios.get("/api/users/list", {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    });
    return result;
  } catch (error) {
    return error.response;
  }
};

const addUser = async (props) => {
  try {
    var result = await axios.post("/api/users/addUser", props, {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    });
    return result;
  } catch (error) {
    return error.response;
  }
};

const updateUser = async (props) => {
  try {
    var result = await axios.post("/api/users/updateUser", props, {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    });
    return result;
  } catch (error) {
    return error.response;
  }
};

const updateMe = async (props) => {
  try {
    var result = await axios.post("/api/users/updateMe", props, {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    });
    return result;
  } catch (error) {
    return error.response;
  }
};

const updatePassword = async (props) => {
  try {
    var result = await axios.post("/api/users/updatePassword", props, {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    });
    return result;
  } catch (error) {
    return error.response;
  }
};

const deleteUser = async (props) => {
  try {
    var result = await axios.post("/api/users/removeUser", props, {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    });
    return result;
  } catch (error) {
    return error.response;
  }
};

const uploadAvatar = async (file) => {
  try {
    let formDataFile = new FormData();
    formDataFile.append('avatar', file);
    const result = await axios.post('/api/common/uploadAvatar', formDataFile, {
      headers: {
        'x-access-token': localStorage.getItem('token')
      },
    });
    return result;
  } catch (error) {
    return error.response;
  }
};

const getMapKeys = async (props) => {
  try {
    var result = await axios.post("/api/users/getMapKeys", props, {
      headers: {
        "Content-Type": "application/json",
        'x-access-token': localStorage.getItem('token')
      },
    });
    return result.data;
  } catch (error) {
    return error.response;
  }
};

const assignUserVehicles = async (props) => {
  try {
    var result = await axios.post("/api/vehicles/assignUserVehicles", props, {
      headers: {
        "Content-Type": "application/json",
        'x-access-token': localStorage.getItem('token')
      },
    });
    return result;
  } catch (error) {
    return error.response;
  }
};

const assignCompanyVehicles = async (props) => {
  try {
    var result = await axios.post("/api/vehicles/assignCompanyVehicles", props, {
      headers: {
        "Content-Type": "application/json",
        'x-access-token': localStorage.getItem('token')
      },
    });
    return result;
  } catch (error) {
    return error.response;
  }
};

const removeDevice = async (props) => {
  try {
    var result = await axios.post("/api/vehicles/remove", props, {
      headers: {
        "Content-Type": "application/json",
        'x-access-token': localStorage.getItem('token')
      },
    });
    return result;
  } catch (error) {
    return error.response;
  }
};

const updateDevice = async (props) => {
  try {
    var result = await axios.post("/api/vehicles/update", props, {
      headers: {
        "Content-Type": "application/json",
        'x-access-token': localStorage.getItem('token')
      },
    });
    return result;
  } catch (error) {
    return error.response;
  }
};

const commandSetting = async (props) => {
  try {
    var result = await axios.post("/api/vehicles/sendCommand", props, {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    });
    return result;
  } catch (error) {
    return error.response;
  }
};

const commandSettingAll = async (props) => {
  try {
    var result = await axios.post("/api/vehicles/sendBatchCommand", props, {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    });
    return result;
  } catch (error) {
    return error.response;
  }
};

const getCommandSettings = async (props) => {
  try {
    var result = await axios.post("api/vehicles/getCommandStatus", props, {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    });
    return result;
  } catch (error) {
    return error.response;
  }
};
const getCommandSettingsById = async (props) => {
  try {
    var result = await axios.post("api/vehicles/getCommandStatusById", props, {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    });
    return result;
  } catch (error) {
    return error.response;
  }
};

const showComandQueue = async (props) => {
  try {
    var result = await axios.post("api/vehicles/showCommand", props, {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    });
    return result;
  } catch (error) {
    return error.response;
  }
};

// get available teltonika avl parameters
const getTeltonikaParams = async (deviceModel) => {
  try {
    var result = await axios.get(`api/common/teltonikaParams/${deviceModel}`, {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    });
    return result;
  } catch (error) {
    return error.response;
  }
};

// get telemetry info
const getTelemetries = async (imei, isLive) => {
  try {
    var result = await axios.post("/api/vehicles/getTelemetries", {
      deviceImei: imei, isLive: isLive
    }, {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    });
    return result;
  } catch (error) {
    return error.response;
  }
};

// add telemetry item for a device
const addTelemetryItem = async (imei, avlId, avlName, heading) => {
  try {
    var result = await axios.post("/api/vehicles/addTelemetryItem", {
      deviceImei: imei, avlId, avlName, heading
    }, {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    });
    return result;
  } catch (error) {
    return error.response;
  }
};

// delete telemetry item for a device
const deleteTelemetryItem = async (imei, avlId) => {
  try {
    var result = await axios.post("/api/vehicles/deleteTelemetryItem", {
      deviceImei: imei, avlId
    }, {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    });
    return result;
  } catch (error) {
    return error.response;
  }
};

// change telemetry order for a device
const changeTelemetryOrder = async (imei, avlId, index) => {
  try {
    var result = await axios.post("/api/vehicles/changeTelemetryOrder", {
      deviceImei: imei, avlId, index
    }, {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    });
    return result;
  } catch (error) {
    return error.response;
  }
};

const getResponse = async (url, type, props) => {
  try {
    let result;
    if (type === "post") {
      result = await axios.post(url, props, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
      });
      return result;
    } else {
      result = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
      });
      return result;
    }
  } catch (error) {
    return error.response;
  }
};


// get all companies
const getCompanies = async () => {
  try {
    var result = await axios.get("/api/company/getAll", {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    });
    return result;
  } catch (error) {
    return error.response;
  }
};

const createCompany = async (props) => {
  try {
    var result = await axios.post("/api/company/create", props, {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    });
    return result;
  } catch (error) {
    return error.response;
  }
};

const updateCompany = async (id, props) => {
  try {
    var result = await axios.post("/api/company/update", {
      id, ...props
    }, {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    });
    return result;
  } catch (error) {
    return error.response;
  }
};

const deleteCompany = async (id) => {
  try {
    var result = await axios.post("/api/company/remove", {
      id
    }, {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    });
    return result;
  } catch (error) {
    return error.response;
  }
};

const activateCompany = async (props) => {
  try {
    var result = await axios.post("/api/company/activate", props, {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    });
    return result;
  } catch (error) {
    return error.response;
  }
};


const getAlertList = async (props) => {
  try {
    var result = await axios.post("api/alerts/list", props, {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    });
    return result;
  } catch (error) {
    return error.response;
  }
};

const getUnreadAlertList = async (props) => {
  try {
    var result = await axios.post("api/alerts/unreadList", props, {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    });
    return result;
  } catch (error) {
    return error.response;
  }
};

const getUnreadAlertCount = async () => {
  try {
    var result = await axios.post("api/alerts/unreadCount", {}, {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    });
    return result;
  } catch (error) {
    return error.response;
  }
};

const setAlertsRead = async (ids) => {
  try {
    var result = await axios.post("api/alerts/setRead", { ids }, {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    });
    return result;
  } catch (error) {
    return error.response;
  }
};

// report events
const exportReports = async (props) => {
  try {
    var result = await axios.post("api/alerts/export", props, {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    });
    return result;
  } catch (error) {
    return error.response;
  }
};

// get geofences
const getGeofences = async () => {
  try {
    var result = await axios.post("/api/geofences/getAll", {}, {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    });
    return result;
  } catch (error) {
    return error.response;
  }
};

// add geofence item
const createGeofence = async (name, deviceImeis, type, geometry) => {
  try {
    var result = await axios.post("/api/geofences/create", {
      name, deviceImeis, type, geometry
    }, {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    });
    return result;
  } catch (error) {
    return error.response;
  }
};

// update geofence item
const updateGeofence = async (id, name, deviceImeis, type, geometry) => {
  try {
    var result = await axios.post("/api/geofences/update", {
      id, name, deviceImeis, type, geometry
    }, {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    });
    return result;
  } catch (error) {
    return error.response;
  }
};

// delete geofence
const deleteGeofence = async (id) => {
  try {
    var result = await axios.post("/api/geofences/remove", {
      id
    }, {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    });
    return result;
  } catch (error) {
    return error.response;
  }
};

// ============== ticket actions ===============
const getTicketList = async (props) => {
  try {
    var result = await axios.post("api/tickets/list", props, {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    });
    return result;
  } catch (error) {
    return error.response;
  }
};

// add ticket item
const createTicket = async (subject, company, content, files) => {
  try {
    var result = await axios.post("/api/tickets/create", {
      subject, company, content, files
    }, {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    });
    return result;
  } catch (error) {
    return error.response;
  }
};

// reply ticket
const replyTicket = async (id, content, files) => {
  try {
    var result = await axios.post("/api/tickets/reply", {
      id, content, files
    }, {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    });
    return result;
  } catch (error) {
    return error.response;
  }
};

// delete ticket
const deleteTickets = async (ids) => {
  try {
    var result = await axios.post("/api/tickets/remove", {
      ids
    }, {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    });
    return result;
  } catch (error) {
    return error.response;
  }
};

// update status
const updateTicketStatus = async (props) => {
  try {
    var result = await axios.post("/api/tickets/updateStatus", props, {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    });
    return result;
  } catch (error) {
    return error.response;
  }
};

const uploadTicketFile = async (file) => {
  try {
    let formDataFile = new FormData();
    formDataFile.append('file', file);
    const result = await axios.post('/api/common/uploadTicketFile', formDataFile, {
      headers: {
        'x-access-token': localStorage.getItem('token')
      },
    });
    return result;
  } catch (error) {
    return error.response;
  }
};


// =================== rule actions =======================
// get all companies
const getRules = async () => {
  try {
    var result = await axios.get("/api/rules/getAll", {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    });
    return result;
  } catch (error) {
    return error.response;
  }
};

const createRule = async (props) => {
  try {
    var result = await axios.post("/api/rules/create", props, {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    });
    return result;
  } catch (error) {
    return error.response;
  }
};

const updateRule = async (id, props) => {
  try {
    var result = await axios.post("/api/rules/update", {
      id, ...props
    }, {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    });
    return result;
  } catch (error) {
    return error.response;
  }
};

const deleteRule = async (id) => {
  try {
    var result = await axios.post("/api/rules/remove", {
      id
    }, {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    });
    return result;
  } catch (error) {
    return error.response;
  }
};

const activateRule = async (props) => {
  try {
    var result = await axios.post("/api/rules/activate", props, {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    });
    return result;
  } catch (error) {
    return error.response;
  }
};

const addRuleVehicles = async (props) => {
  try {
    var result = await axios.post("/api/rules/addVehicles", props, {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    });
    return result;
  } catch (error) {
    return error.response;
  }
};

// ============== ACS Users actions ===============
const getACSUserList = async (props) => {
  try {
    var result = await axios.post("api/acsusers/list", props, {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    });
    return result;
  } catch (error) {
    return error.response;
  }
};

// add or update item
const upsertACSUser = async (props) => {
  try {
    var result = await axios.post("/api/acsusers/upsert", props, {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    });
    return result;
  } catch (error) {
    return error.response;
  }
};


// delete ticket
const deleteACSUsers = async (ids) => {
  try {
    var result = await axios.post("/api/acsusers/remove", {
      ids
    }, {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    });
    return result;
  } catch (error) {
    return error.response;
  }
};


export {
  getMapKeys,

  userRegister,
  signInUser,
  signInUserAs,
  resendVerifyEmail,
  resetPassword,
  getUser,
  activateUser,
  uploadAvatar,
  getUsers,
  addUser,
  updateUser,
  deleteUser,
  updateMe,
  updatePassword,

  commandSetting,
  commandSettingAll,
  getCommandSettingsById,
  getCommandSettings,
  showComandQueue,

  getUserNameList,
  getHistoryList,
  getHistoryDates,
  updateDevice,
  removeDevice,
  assignUserVehicles,
  assignCompanyVehicles,

  getResponse,

  getTeltonikaParams,

  getTelemetries,
  addTelemetryItem,
  deleteTelemetryItem,
  changeTelemetryOrder,

  getCompanies,
  createCompany,
  updateCompany,
  deleteCompany,
  activateCompany,

  getAlertList,
  getUnreadAlertList,
  getUnreadAlertCount,
  setAlertsRead,

  exportReports,

  getGeofences,
  createGeofence,
  updateGeofence,
  deleteGeofence,

  getTicketList,
  createTicket,
  replyTicket,
  deleteTickets,
  updateTicketStatus,
  uploadTicketFile,

  getRules,
  createRule,
  updateRule,
  deleteRule,
  activateRule,
  addRuleVehicles,

  getACSUserList,
  upsertACSUser,
  deleteACSUsers,
  
};
