import React, { useEffect, useState } from "react";

import "./acs.css";
import { sendCommandMsg, socket } from "../../socket";
import { getPacketDateValue, getPacketIntValue, getPacketStrValue, getStringFromBytes, getValueStr, sleep } from "../../utils/globals";
import { Input, notification, Radio, Space } from "antd";
import LoadingSpinner from "../commons/loading/LoadingSpinner";
import { upsertACSUser } from "../../services/axios";
import TextArea from "antd/es/input/TextArea";


const UserImport = ({ selectedVehData, onClose }) => {

  const [isLoading, setIsLoading] = useState(false);
  const [importBy, setImportBy] = useState(0);
  const [userIds, setUserIds] = useState([]);
  const [importUserIds, setImportUserIds] = useState([""]);
  const [importLogs, setImportLogs] = useState([]);

  useEffect(() => {
    const onUpdateCommandResponse = async (cmdData) => {
      // console.log("🚀 ~ onUpdateCommandResponse ~ cmdData:", cmdData)

      const { deviceImei, matchingId, response, result } = cmdData;
      if (deviceImei != selectedVehData.deviceImei || result != '00') {
        return;
      }

      if (matchingId == "06") {
        // get all user ids 

        let uids = String(getPacketStrValue(response, 4)).match(/.{1,8}/g);
        setUserIds(uids);
        // console.log("🚀 ~ onUpdateCommandResponse ~ uids:", uids)
        setImportLogs(prev =>
          [
            ...prev,
            `UserIDs of Device:\n ${uids.map(uid => parseInt(uid, 16)).join(', ')}`
          ]
        );
      } else if (matchingId == "08") {
        // get one user data

        let fpCount = getPacketIntValue(response, 85, 1);
        let fpTempSize = getPacketIntValue(response, 86, 2);
        let fpTempData = getPacketStrValue(response, 88, fpCount * fpTempSize);

        let userInfo = {
          device: selectedVehData.deviceImei,
          userId: getPacketIntValue(response, 0, 4),
          cardNo: getPacketIntValue(response, 4, 8),
          empId: getStringFromBytes(getPacketStrValue(response, 12, 10)),
          userName: getStringFromBytes(getPacketStrValue(response, 22, 31)),
          expireStatus: getPacketIntValue(response, 53, 1),
          expireStart: getPacketDateValue(response, 54),
          expireEnd: getPacketDateValue(response, 59),
          status: getPacketIntValue(response, 64, 1),
          type: getPacketIntValue(response, 65, 1),
          verifyMode: getPacketIntValue(response, 70, 1),
          fpCount: fpCount,
          fpTempSize: fpTempSize,
          fpTempData: fpTempData,
        };
        await upsertACSUser(userInfo);

        setImportLogs(prev =>
          [
            ...prev,
            // `Synchronized:\n [userId] - ${userInfo.userId}, [userName] - ${userInfo.userName}, [cardNo] - ${userInfo.cardNo}`
            `Synchronized:\n ${JSON.stringify(userInfo)}`
          ]
        );
      }
    }
    socket.on('updateCommandResponse', onUpdateCommandResponse);

    return () => {
      socket.off('updateCommandResponse', onUpdateCommandResponse);
    };
  }, [selectedVehData]);

  useEffect(() => {
    loadUserInfo();
  }, [userIds]);

  const loadUserInfo = async () => {

    // start loading user info
    setIsLoading(true);

    const deviceImei = selectedVehData.deviceImei;
    for (const uid of userIds) {
      sendCommandMsg(null, deviceImei, "08:" + uid);
      await sleep(200);
    }

    setIsLoading(false);
  }

  const handleAdd = async () => {
    setImportUserIds([...importUserIds, ""]);
  }

  const handleChangeUID = async (index, value) => {
    setImportUserIds([
      ...(importUserIds.slice(0, index)),
      value,
      ...(importUserIds.slice(index + 1))
    ]);
  }

  const handleRemove = async (index) => {
    if (importUserIds.length <= 1) {
      return;
    }
    setImportUserIds([
      ...(importUserIds.slice(0, index)),
      ...(importUserIds.slice(index + 1))
    ]);
  }

  const handleImport = async () => {
    if (importBy == 1) {
      let uids = [];
      for (const uid of importUserIds) {
        if (uid.length > 0) {
          uids.push(uid);
        }
      }
      if (uids.length == 0) {
        return notification.error({ description: "Please Enter the User IDs!" });
      }
      setUserIds(uids.map(uid => getValueStr(uid, 4)));
    } else {
      sendCommandMsg(null, selectedVehData.deviceImei, "06");
    }
  }

  return (
    <>
      <div className={`acs-panel`}>
        <LoadingSpinner visible={isLoading} global={false} timeout={60} onTimeout={() => setIsLoading(false)} />
        <div className="panel-title">
          <div>{selectedVehData.vehicleName} {'>'} Users Import</div>
        </div>

        <div className="panel-body">
          <div className="user-import">
            <div className="">
              <div className='position-relative text-end text-md-center mb-5'>
                <span className="fw-bold red-black position-absolute top-0 start-0" style={{ textDecoration: "underline" }}>
                  Import Person’s Info from Device
                </span>
                <span className='bg-red-black text-white rounded-3 px-5 py-2' style={{ fontSize: "1.2rem" }}>
                  {selectedVehData.vehicleName}
                </span>
              </div>

              <div className="row">
                <div className="col-md">

                  <div className="mb-4">Import Person</div>
                  <div className="ps-2 mb-5">
                    <Radio.Group value={importBy} onChange={e => setImportBy(e.target.value)}>
                      <Space direction="vertical">
                        <Radio value={0}>All</Radio>
                        <Radio value={1}>Specified Employee No.</Radio>
                      </Space>
                    </Radio.Group>
                  </div>

                  {importBy == 1 &&
                    <>
                      {importUserIds.map((uid, index) =>
                        <div className="mb-4" key={index}>
                          <div className="mb-3">Enter ID</div>
                          <div className="d-flex flex-row gap-4" style={{ maxWidth: "35rem" }}>
                            <Input placeholder="Enter User ID"
                              value={uid}
                              onChange={e => handleChangeUID(index, e.target.value)}
                            />
                            <img src="/assets/close.svg" className="cursor-pointer" style={{ width: 25 }}
                              onClick={() => handleRemove(index)}
                            />
                          </div>
                        </div>
                      )}

                      <div className="mt-5 mb-5">
                        <button className="tab-button" onClick={handleAdd} style={{ borderRadius: "1rem" }}>Add</button>
                      </div>
                    </>
                  }
                </div>
                <div className="col-md">
                  <div className="mb-4">Import Logs</div>
                  <TextArea
                    value={importLogs.join("\n\n")}
                    readOnly
                    onChange={() => { }}
                    rows={15}
                  />
                </div>
              </div>
            </div>

            <div className="w-100 d-flex justify-content-center gap-4 mt-4">
              <button className="tab-button" onClick={onClose}>Cancel</button>
              <button className="tab-button" onClick={handleImport}>Import</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserImport;
