import { useEffect, useState } from "react";
import { showConfirm, sleep } from "../../../utils/globals";

import "./CommandSettingPanel.css";

const CommandSettingPanel = ({
  title,
  description,
  date,
  content,
  defaultOpen = false,
  onRefreshAction,
  onSendAction,
  waitTimeout = 10000
}) => {

  const [isOpen, setIsOpen] = useState(defaultOpen);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("");

  const handleSendAction = () => {
    showConfirm('Do you really want to send setting to selected devices?')
      .then(async () => {
        setIsLoading(true);
        setLoadingText("sending ...");

        await onSendAction();

        await sleep(waitTimeout);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }

  const handleRefreshAction = () => {
    showConfirm('Do you want to request the setting from device?')
      .then(async () => {
        setIsLoading(true);
        setLoadingText("reading ...");

        await onRefreshAction();

        await sleep(waitTimeout);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    if (onRefreshAction) {
      onRefreshAction(true);
    }
  }, []);

  useEffect(() => {
    setIsLoading(false);
  }, [date]);

  return (
    <div className="mb-5 bg-white overflow-hidden command-panel" style={{ borderRadius: "1rem" }}>
      <div className="w-100 d-flex align-items-center justify-content-between px-4 title">
        <div className="d-flex flex-column justify-content-center py-1 cursor-pointer" onClick={() => setIsOpen(!isOpen)}>
          <p className="panel-title">{title}</p>
          <p className="panel-description">{description}&nbsp;</p>
        </div>
        <div className="d-flex align-items-center px-0">
          {onRefreshAction &&
            <img
              className="cursor-pointer mx-2"
              src="/assets/refresh.svg"
              alt="none"
              onClick={handleRefreshAction}
            />
          }
          {onSendAction &&
            <img
              className="cursor-pointer mx-2"
              src="/assets/S.svg"
              alt="none"
              onClick={handleSendAction}
            />
          }
          {content &&
            <img
              className={`cursor-pointer mx-2 expand-collapse ${isOpen ? 'expanded' : ''}`}
              src="/assets/down.svg"
              alt="none"
              onClick={() => setIsOpen(!isOpen)}
            />
          }
        </div>
      </div>
      <div className={`panel-date d-flex align-items-center ${isLoading ? 'loadings' : ''}`}>
        <p className="mb-0 ps-4">{isLoading ? loadingText : date}</p>
      </div>
      <div className={`panel-content ${isLoading ? 'loadings' : ''}`}>
        {isOpen && (content)}
      </div>
    </div>
  );
}

export default CommandSettingPanel;