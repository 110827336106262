import { getAngleBetweenPoints } from "../../utils/globals";
import { GET_DEVICES, UPDATE_DEVICE_GSMNUMBERS, UPDATE_DEVICE_STATUS } from "../store/types";

const initialState = {
    devices: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_DEVICES:
            if (action.liveInfo) {
                return {
                    ...state,
                    devices: action.payload,
                };
            } else {
                let newDevices = action.payload.map(item => {
                    let prevItem = state.devices.find(dev => dev.deviceImei == item.deviceImei);
                    if (prevItem) {
                        return {
                            ...item,
                            connected: prevItem.connected,
                            idle: prevItem.idle,
                            gpsFixed: prevItem.gpsFixed,
                            gpsFixedDate: prevItem.gpsFixedDate,
                            ignition: prevItem.ignition,
                            movements: prevItem.movements,
                            din1: prevItem.din1,
                            iccid: prevItem.iccid,
                            dout1: prevItem.dout1,
                            lat: prevItem.lat,
                            lng: prevItem.lng,
                            speed: prevItem.speed,
                            fuel: prevItem.fuel,
                            battery: prevItem.battery,
                            external: prevItem.external,
                            temperature: prevItem.temperature,
                            address: prevItem.address,
                            stopTime: prevItem.stopTime,
                            status: prevItem.status,
                            angle: prevItem.angle,
                            transferDate: prevItem.transferDate,
                        };
                    } else {
                        return item;
                    }
                });
                return {
                    ...state,
                    devices: newDevices
                }
            }

        case UPDATE_DEVICE_STATUS:
            return {
                ...state,
                devices: state.devices.map(item => {
                    if (item.deviceImei == action.payload.deviceImei) {
                        return {
                            ...item,
                            ...(action.payload),
                            ...(item.vehicleType == "ACS" ? {
                                connected: true
                            } : {
                                angle: (item.lat != action.payload.lat || item.lng != action.payload.lng) ? getAngleBetweenPoints(item.lat, item.lng, action.payload.lat, action.payload.lng) : item.angle,
                                gpsFixed: action.payload.gnss,
                                gpsFixedDate: (action.payload.gnss == 1 ? action.payload.transferDate : item.gpsFixedDate),
                                idle: (action.payload.speed > 0 ? false : true),
                                stopTime: ((action.payload.ignition == 1) ? action.payload.transferDate : item.stopTime),
                            })
                        }
                    } else {
                        return {
                            ...item,
                            connected: (Date.now() - new Date(item.transferDate).getTime() > (item.vehicleType == "ACS" ? 30 : 3) * 60000 ? false : item.connected)
                        }
                    }
                })
            }

        case UPDATE_DEVICE_GSMNUMBERS:
            return {
                ...state,
                devices: state.devices.map(item => {
                    if (item.deviceImei == action.payload.deviceImei) {
                        return {
                            ...item,
                            gsmNumbers: action.payload.gsmNumbers
                        }
                    } else {
                        return item;
                    }
                })
            }

        default:
            return state;
    }
}
