
export const Sensors = [
  {
    id: "cam_dual",
    label: "Dual Cam",
    type: "Teltonika",
    path: "/assets/Teltonika/Accessories/Camera/DualCam.png"
  },
  {
    id: "cam_adas",
    label: "ADAS",
    type: "Teltonika",
    path: "/assets/Teltonika/Accessories/Camera/ADAS.svg"
  },
  {
    id: "cam_dsm",
    label: "DSM",
    type: "Teltonika",
    path: "/assets/Teltonika/Accessories/Camera/DSM.svg"
  },
  {
    id: "cam_dash",
    label: "DashCam",
    type: "Teltonika",
    path: "/assets/Teltonika/Accessories/Camera/DashCam.svg"
  },
  {
    id: "cam_dual_rup",
    label: "Dual Cam Ruptela",
    type: "Ruptela",
    path: "/assets/Teltonika/Accessories/Camera/DualCam.png"
  },
  {
    id: "sen_allcan",
    label: "ALL-CAN300_08",
    type: "Teltonika",
    path: "/assets/Teltonika/Accessories/Sensor/ALL-CAN300_08.png"
  },
  {
    id: "sen_eye",
    label: "EYE SENSOR",
    type: "Teltonika",
    path: "/assets/Teltonika/Accessories/Sensor/BTSMP1.png"
  },
  {
    id: "sen_ecan",
    label: "ECAN01",
    type: "Teltonika",
    path: "/assets/Teltonika/Accessories/Sensor/ECAN01_TOP.png"
  },

  {
    id: "sen_ecan_rup",
    label: "ECAN01 Ruptela",
    type: "Ruptela",
    path: "/assets/Teltonika/Accessories/Sensor/ECAN01_TOP.png"
  },
]


export const DeviceModels = [
  {
    "id": "1",
    "type": "Teltonika",
    "device": "FMB110",
    "path": "/assets/Teltonika/FMB110_4000x4000_9.png"
  },
  {
    "id": "2",
    "type": "Teltonika",
    "device": "FMB120",
    "path": "/assets/Teltonika/FMB120_4000X4000_9.png"
  },
  {
    "id": "3",
    "type": "Teltonika",
    "device": "FMB122",
    "path": "/assets/Teltonika/FMB122_PNG_4000x4000_9.png"
  },
  {
    "id": "4",
    "type": "Teltonika",
    "device": "FMB125",
    "path": "/assets/Teltonika/FMB125_4000X4000_9.png"
  },
  {
    "id": "5",
    "type": "Teltonika",
    "device": "FMB130",
    "path": "/assets/Teltonika/FMB130_08.png"
  },
  {
    "id": "6",
    "type": "Teltonika",
    "device": "FMB202",
    "path": "/assets/Teltonika/FMB202_009.png"
  },
  {
    "id": "7",
    "type": "Teltonika",
    "device": "FMB204",
    "path": "/assets/Teltonika/FMB204_009.png"
  },
  {
    "id": "8",
    "type": "Teltonika",
    "device": "FMB208",
    "path": "/assets/Teltonika/FMB208_09_Demo.png"
  },
  {
    "id": "9",
    "type": "Teltonika",
    "device": "FMB209",
    "path": "/assets/Teltonika/FMB209_4000X4000_9.png"
  },
  {
    "id": "10",
    "type": "Teltonika",
    "device": "FMB225",
    "path": "/assets/Teltonika/FMB225-front-2023-03-01_(cropped).png"
  },
  {
    "id": "11",
    "type": "Teltonika",
    "device": "FMB230",
    "path": "/assets/Teltonika/FMB230-front-2023-03-01_(cropped).png"
  },
  {
    "id": "12",
    "type": "Teltonika",
    "device": "FMC125",
    "path": "/assets/Teltonika/FMC125-MBIBO-2022-10-03_4000x4000_9.png"
  },
  {
    "id": "13",
    "type": "Teltonika",
    "device": "FMC130",
    "path": "/assets/Teltonika/FMC130-ME1-2022-09-30_4000x4000-9.png"
  },
  {
    "id": "14",
    "type": "Teltonika",
    "device": "FMC13A",
    "path": "/assets/Teltonika/FMC13A_008.png"
  },
  {
    "id": "15",
    "type": "Teltonika",
    "device": "FMC225",
    "path": "/assets/Teltonika/FMC225_4000x4000_9.png"
  },
  {
    "id": "16",
    "type": "Teltonika",
    "device": "FMC230",
    "path": "/assets/Teltonika/FMC230-4000x4000-2022-10-03-005.png"
  },
  {
    "id": "17",
    "type": "Teltonika",
    "device": "FMM125",
    "path": "/assets/Teltonika/FMM125_4000X4000_9.png"
  },
  {
    "id": "18",
    "type": "Teltonika",
    "device": "FMM130",
    "path": "/assets/Teltonika/FMM130-QG5-2022-10-04_4000x4000-008.png"
  },
  {
    "id": "19",
    "type": "Teltonika",
    "device": "FMM13A",
    "path": "/assets/Teltonika/FMM13A_4000x4000_09.png"
  },
  {
    "id": "20",
    "type": "Teltonika",
    "device": "FMM230",
    "path": "/assets/Teltonika/FMM230_4000x4000_9.png.png"
  },
  {
    "id": "21",
    "type": "Teltonika",
    "device": "MSP500",
    "path": "/assets/Teltonika/MSP500-9-demo.png"
  },
  {
    "id": "22",
    "type": "Teltonika",
    "device": "FMB240",
    "path": "/assets/Teltonika/FMB240_2.png"
  },
  {
    "id": "23",
    "type": "Teltonika",
    "device": "FMB641",
    "path": "/assets/Teltonika/FMB641_4000X4000_9.png"
  },
  {
    "id": "24",
    "type": "Teltonika",
    "device": "FMC640",
    "path": "/assets/Teltonika/FMC640_08.png"
  },
  {
    "id": "25",
    "type": "Teltonika",
    "device": "FMC650",
    "path": "/assets/Teltonika/FMC650_08.png"
  },
  {
    "id": "26",
    "type": "Teltonika",
    "device": "FMM650",
    "path": "/assets/Teltonika/FMM650_08.png"
  },
  {
    "id": "27",
    "type": "Teltonika",
    "device": "GH5200",
    "path": "/assets/Teltonika/GH5200_demo_07.png"
  },
  {
    "id": "28",
    "type": "Teltonika",
    "device": "TAT100",
    "path": "/assets/Teltonika/TAT100-ASSET-TRACKER.png"
  },
  {
    "id": "29",
    "type": "Teltonika",
    "device": "TAT140",
    "path": "/assets/Teltonika/TAT140_asset_tracker.png"
  },
  {
    "id": "101",
    "type": "Teltonika",
    "device": "TAT141",
    "path": "/assets/Teltonika/TAT100-ASSET-TRACKER.png"
  },
  {
    "id": "102",
    "type": "Teltonika",
    "device": "TAT240",
    "path": "/assets/Teltonika/TAT100-ASSET-TRACKER.png"
  },
  {
    "id": "30",
    "type": "Teltonika",
    "device": "TMT250",
    "path": "/assets/Teltonika/TMT250_2_demo.png"
  },
  {
    "id": "31",
    "type": "Teltonika",
    "device": "FMB900",
    "path": "/assets/Teltonika/FMB900_4000X4000_9.png"
  },
  {
    "id": "32",
    "type": "Teltonika",
    "device": "FMB910",
    "path": "/assets/Teltonika/FMB910_4000X4000_9.png"
  },
  {
    "id": "33",
    "type": "Teltonika",
    "device": "FMB920",
    "path": "/assets/Teltonika/FMB920_4000X4000_9.png"
  },
  {
    "id": "34",
    "type": "Teltonika",
    "device": "FMB930",
    "path": "/assets/Teltonika/FMB930-4000X4000.9.png"
  },
  {
    "id": "35",
    "type": "Teltonika",
    "device": "FMB965",
    "path": "/assets/Teltonika/FMB965-4000x4000-Camera_9.png"
  },
  {
    "id": "36",
    "type": "Teltonika",
    "device": "FMC920",
    "path": "/assets/Teltonika/FMC920-QKIBO-2022-09-28-4000x4000-9.png"
  },
  {
    "id": "37",
    "type": "Teltonika",
    "device": "FMM920",
    "path": "/assets/Teltonika/FMM920-Q3IBO-2022-09-28-4000x4000-9.png"
  },
  {
    "id": "103",
    "type": "Teltonika",
    "device": "FTC921",
    "path": "/assets/Teltonika/FMB120_4000X4000_9.png"
  },
  {
    "id": "104",
    "type": "Teltonika",
    "device": "FTC961",
    "path": "/assets/Teltonika/FMB120_4000X4000_9.png"
  },
  {
    "id": "38",
    "type": "Teltonika",
    "device": "FMB140",
    "path": "/assets/Teltonika/FMB140_4000X4000_09.png"
  },
  {
    "id": "39",
    "type": "Teltonika",
    "device": "FMB150",
    "path": "/assets/Teltonika/FMB150_4000X4000_09.png"
  },
  {
    "id": "40",
    "type": "Teltonika",
    "device": "FMC150",
    "path": "/assets/Teltonika/FMC150-QJIBO-2022-10-05_4000x4000-9.png"
  },
  {
    "id": "41",
    "type": "Teltonika",
    "device": "FMM150",
    "path": "/assets/Teltonika/FMM150-Q3IBO-2022-10-05_4000x4000-9.png"
  },
  {
    "id": "105",
    "type": "Teltonika",
    "device": "FMC240",
    "path": "/assets/Teltonika/FMC150-QJIBO-2022-10-05_4000x4000-9.png"
  },
  {
    "id": "106",
    "type": "Teltonika",
    "device": "FMC250",
    "path": "/assets/Teltonika/FMC150-QJIBO-2022-10-05_4000x4000-9.png"
  },
  {
    "id": "107",
    "type": "Teltonika",
    "device": "FMM250",
    "path": "/assets/Teltonika/FMM150-Q3IBO-2022-10-05_4000x4000-9.png"
  },
  {
    "id": "42",
    "type": "Teltonika",
    "device": "TFT100",
    "path": "/assets/Teltonika/TFT100_01.png"
  },
  {
    "id": "43",
    "type": "Teltonika",
    "device": "TST100",
    "path": "/assets/Teltonika/TST100_demo_00.png"
  },
  {
    "id": "44",
    "type": "Teltonika",
    "device": "FM3001",
    "path": "/assets/Teltonika/FMB001_4000X4000_9.png"
  },
  {
    "id": "45",
    "type": "Teltonika",
    "device": "FM3612",
    "path": "/assets/Teltonika/FMB120_4000X4000_9.png"
  },
  {
    "id": "46",
    "type": "Teltonika",
    "device": "FM3622",
    "path": "/assets/Teltonika/FMB120_4000X4000_9.png"
  },
  {
    "id": "47",
    "type": "Teltonika",
    "device": "FM36M1",
    "path": "/assets/Teltonika/FMB120_4000X4000_9.png"
  },
  {
    "id": "48",
    "type": "Teltonika",
    "device": "FM6300",
    "path": "/assets/Teltonika/FMB120_4000X4000_9.png"
  },
  {
    "id": "49",
    "type": "Teltonika",
    "device": "FM6320",
    "path": "/assets/Teltonika/FMB120_4000X4000_9.png"
  },
  {
    "id": "50",
    "type": "Teltonika",
    "device": "FMA110",
    "path": "/assets/Teltonika/FMB120_4000X4000_9.png"
  },
  {
    "id": "51",
    "type": "Teltonika",
    "device": "FMA120",
    "path": "/assets/Teltonika/FMB120_4000X4000_9.png"
  },
  {
    "id": "52",
    "type": "Teltonika",
    "device": "FMA202",
    "path": "/assets/Teltonika/FMB120_4000X4000_9.png"
  },
  {
    "id": "53",
    "type": "Teltonika",
    "device": "FMA204",
    "path": "/assets/Teltonika/FMB120_4000X4000_9.png"
  },
  {
    "id": "108",
    "type": "Teltonika",
    "device": "FMB206",
    "path": ""
  },
  {
    "id": "54",
    "type": "Teltonika",
    "device": "FMB630",
    "path": "/assets/Teltonika/FMB120_4000X4000_9.png"
  },
  {
    "id": "55",
    "type": "Teltonika",
    "device": "FMB640",
    "path": "/assets/Teltonika/FMB120_4000X4000_9.png"
  },
  {
    "id": "56",
    "type": "Teltonika",
    "device": "FMB962",
    "path": "/assets/Teltonika/FMB120_4000X4000_9.png"
  },
  {
    "id": "57",
    "type": "Teltonika",
    "device": "FMB964",
    "path": "/assets/Teltonika/FMB120_4000X4000_9.png"
  },
  {
    "id": "58",
    "type": "Teltonika",
    "device": "FMM640",
    "path": "/assets/Teltonika/FMB120_4000X4000_9.png"
  },
  {
    "id": "59",
    "type": "Teltonika",
    "device": "FMU125",
    "path": "/assets/Teltonika/FMB120_4000X4000_9.png"
  },
  {
    "id": "60",
    "type": "Teltonika",
    "device": "FMU126",
    "path": "/assets/Teltonika/FMB120_4000X4000_9.png"
  },
  {
    "id": "61",
    "type": "Teltonika",
    "device": "FMU130",
    "path": "/assets/Teltonika/FMB120_4000X4000_9.png"
  },
  {
    "id": "62",
    "type": "Teltonika",
    "device": "MTB100",
    "path": "/assets/Teltonika/FMB120_4000X4000_9.png"
  },
  {
    "id": "63",
    "type": "Teltonika",
    "device": "TSM232",
    "path": "/assets/Teltonika/FMB120_4000X4000_9.png"
  },
  {
    "id": "64",
    "type": "Teltonika",
    "device": "FMB010",
    "path": "/assets/Teltonika/FMB010_4000X4000_9.png"
  },
  {
    "id": "65",
    "type": "Teltonika",
    "device": "FMB020",
    "path": "/assets/Teltonika/FMB020-4000x4000-04.png"
  },
  {
    "id": "66",
    "type": "Teltonika",
    "device": "FMC800",
    "path": "/assets/Teltonika/FMC800_4000x4000-3.png"
  },
  {
    "id": "109",
    "type": "Teltonika",
    "device": "FMC880",
    "path": ""
  },
  {
    "id": "67",
    "type": "Teltonika",
    "device": "FMM800",
    "path": "/assets/Teltonika/FMM800_4000x4000.3.png"
  },
  {
    "id": "68",
    "type": "Teltonika",
    "device": "FMM80A",
    "path": "/assets/Teltonika/FMM80A-Q2IBO-2022-09-29-4000x4000-9.png"
  },
  {
    "id": "69",
    "type": "Teltonika",
    "device": "FMM880",
    "path": "/assets/Teltonika/FMM800_4000x4000.png"
  },
  {
    "id": "70",
    "type": "Teltonika",
    "device": "FMP100",
    "path": "/assets/Teltonika/FMP100_4000x4000_9.png.png"
  },
  {
    "id": "71",
    "type": "Teltonika",
    "device": "FMT100",
    "path": "/assets/Teltonika/FMT100-9.png"
  },
  {
    "id": "110",
    "type": "Teltonika",
    "device": "FTC881",
    "path": ""
  },
  {
    "id": "111",
    "type": "Teltonika",
    "device": "FMB001",
    "path": "/assets/Teltonika/FMB001_4000X4000_9.png"
  },
  {
    "id": "73",
    "type": "Teltonika",
    "device": "FMB002",
    "path": "/assets/Teltonika/FMB002-4000x4000-4.png"
  },
  {
    "id": "74",
    "type": "Teltonika",
    "device": "FMB003",
    "path": "/assets/Teltonika/FMB003_N-4000x4000-8.png"
  },
  {
    "id": "75",
    "type": "Teltonika",
    "device": "FMC001",
    "path": "/assets/Teltonika/FMC001_4000X4000_9.png"
  },
  {
    "id": "76",
    "type": "Teltonika",
    "device": "FMC003",
    "path": "/assets/Teltonika/FMC003_MCIBO_2022-08-19_4000x4000-9.png"
  },
  {
    "id": "77",
    "type": "Teltonika",
    "device": "FMC00A",
    "path": "/assets/Teltonika/FMC00A_4000x4000_9.png"
  },
  {
    "id": "78",
    "type": "Teltonika",
    "device": "FMM001",
    "path": "/assets/Teltonika/FMM001-Q3IBO-2022-09-28-4000x4000-9.png"
  },
  {
    "id": "79",
    "type": "Teltonika",
    "device": "FMM003",
    "path": "/assets/Teltonika/FMM003-Q3IBO-2022-09-29-4000x4000-9.png"
  },
  {
    "id": "80",
    "type": "Teltonika",
    "device": "FMM00A",
    "path": "/assets/Teltonika/FMM00A-Q2IBO-2022-09-29-4000x4000-9.png"
  },

  {
    "id": "101",
    "type": "Ruptela",
    "device": "Trace5 NA",
    "path": "/assets/Ruptela/Trace5.webp"
  },
  {
    "id": "102",
    "type": "Ruptela",
    "device": "FM-Eco4 light+ RS T",
    "path": "/assets/Ruptela/FM-Eco4 light+ RS T.png"
  },
  {
    "id": "103",
    "type": "Ruptela",
    "device": "FM-Eco4 light+ T",
    "path": "/assets/Ruptela/FM-Eco4 light+ T.png"
  },
  {
    "id": "104",
    "type": "Ruptela",
    "device": "FM-Eco4+ E RS T",
    "path": "/assets/Ruptela/FM-Eco4+ E RS T.png"
  },
  {
    "id": "105",
    "type": "Ruptela",
    "device": "FM-Eco4+ S",
    "path": "/assets/Ruptela/FM-Eco4+ S.png"
  },
  {
    "id": "106",
    "type": "Ruptela",
    "device": "FM-Eco4+",
    "path": "/assets/Ruptela/FM-Eco4+.png"
  },
  {
    "id": "107",
    "type": "Ruptela",
    "device": "HCV5 Lite",
    "path": "/assets/Ruptela/HCV5 Lite.png"
  },
  {
    "id": "108",
    "type": "Ruptela",
    "device": "HCV5",
    "path": "/assets/Ruptela/HCV5.png"
  },
  {
    "id": "109",
    "type": "Ruptela",
    "device": "Plug5",
    "path": "/assets/Ruptela/Plug5.png"
  },

  {
    "id": "201",
    "type": "Biosense",
    "device": "BioSense I",
    "path": "/assets/Biosense/BioSense.png"
  },
];
