import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { DeviceType } from "../../../common/common";
import { DeviceModels } from "../../../utils/mockup";

const ViewDeviceAC = () => {
  const navigate = useNavigate();
  const getData = useLocation();

  const [formData, setFormData] = useState({
    vehicleType: "ACS",
    plate: getData.state.plate,
    imei: getData.state.imei,
    deviceType: getData.state.device,
    model: getData.state.model,
    company: getData.state.company?.name,
  });

  return (
    <div className="add-device-main w-100 h-100">
      <p>Add Device</p>
      <div className="add-device-div1 d-flex flex-column align-items-center p-5 mx-5">
        <div className="right-subsub1-sub1-add-device-div1 d-flex align-items-end p-5 gap-3 mb-5">
          <div className="d-flex flex-column gap-4">
            <div className="add-input-container d-flex flex-column position-relative">
              <label>Item Type</label>
              <input
                className="normal-input"
                value="Access Controller"
                type="text"
                onChange={() => { }}
                readOnly
              />
              <img
                className="vehicle-icon position-absolute"
                src="/assets/ACS.svg"
                alt="none"
              />
            </div>
            <div className="add-input-container d-flex flex-column">
              <label>Device Name</label>
              <input
                className="normal-input"
                value={formData.plate}
                name="plate"
                type="text"
                maxLength={13}
                onChange={() => { }}
                readOnly
              />
            </div>

            <div className="add-input-container d-flex flex-column">
              <label>Company</label>
              <input
                className="normal-input"
                value={formData.company}
                name="company"
                type="text"
                onChange={() => { }}
                readOnly
              />
            </div>

            <div className="add-input-container d-flex flex-column">
              <p className="p-0">Device Details</p>
              <label>Device MAC</label>
              <input
                className="normal-input"
                value={formData.imei}
                name="imei"
                type="text"
                onChange={() => { }}
                readOnly
              />
            </div>
            <div className="add-input-container d-flex flex-column">
              <label>Device Type</label>
              <select
                className="normal-input"
                value={formData.deviceType}
                name="deviceType"
                type="text"
                onChange={(e) => { }}
                disabled
              >
                <option value={DeviceType.Biosense}>{DeviceType.Biosense}</option>
              </select>
            </div>

            <div className="add-input-container d-flex flex-column">
              <label>Device Model</label>
              <input
                className="normal-input"
                value={formData.model}
                name="model"
                type="text"
                onChange={() => { }}
                readOnly
              />
            </div>
          </div>
          <div className="mt-5 text-center" style={{ width: "25rem" }}>
            <img
              className="" style={{ maxWidth: "100%", maxHeight: "300px" }}
              src={
                DeviceModels.filter((item) => item.device === formData.model)[0]
                  ? DeviceModels.filter((item) => item.device === formData.model)[0].path
                  : "/assets/chip.png"
              }
              alt="none"
            />
          </div>
        </div>
        <div className="sub5-right-subsub1-sub1-add-device-div1 d-flex justify-content-center w-100">
          <button className='me-5' onClick={() => navigate("/Devices")}>Back</button>
        </div>
      </div>
    </div>
  );
};

export default ViewDeviceAC;
