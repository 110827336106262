import { socket } from "../../socket";
import { SET_USER, LOG_OUT, UPDATE_USER } from "../store/types";

const initialState = {
  user: localStorage.getItem("user") !== "undefined" ? JSON.parse(localStorage.getItem("user")) : null,
  isAuthenticated: !!localStorage.getItem("token"),
  token: localStorage.getItem("token"),
  gm: localStorage.getItem("gm") ? JSON.parse(localStorage.getItem("gm")) : null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_USER:
      let gm = state.gm;
      let user = state.user;
      let token = state.token;
      if (action.impersonate) {
        localStorage.setItem("token_m", localStorage.getItem("token"));
        localStorage.setItem("user_m", localStorage.getItem("user"));
      } 

      if (action.deimpersonate) {
        localStorage.setItem("token", localStorage.getItem("token_m"));
        localStorage.setItem("user", localStorage.getItem("user_m"));

        user = JSON.parse(localStorage.getItem("user_m"));
        token = localStorage.getItem("token_m");

        localStorage.removeItem("token_m");
        localStorage.removeItem("user_m");
      } else {
        localStorage.setItem("token", action.payload.token);
        localStorage.setItem("user", JSON.stringify(action.payload.user));
        localStorage.setItem("gm", JSON.stringify(action.payload.gm));

        user = action.payload.user;
        token = action.payload.token;
        gm = action.payload.gm;
      }

      return {
        isAuthenticated: true,
        user: user,
        token: token,
        gm: gm
      };

    case UPDATE_USER:
      localStorage.setItem("user", JSON.stringify(action.payload));

      return {
        ...state,
        user: action.payload,
      };

    case LOG_OUT:
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("role");

      localStorage.removeItem("token_m");
      localStorage.removeItem("user_m");
      localStorage.removeItem("role_m");

      // socket disconnect
      socket.disconnect();

      return {
        user: null,
        isAuthenticated: false,
        token: null,
        gm: null
      };

    default:
      return state;
  }
}
